import React from 'react';
import { Box, Button, Card, CardMedia, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { EventAvailable, LocationOnOutlined } from '@mui/icons-material';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { LiaStethoscopeSolid } from "react-icons/lia";
import { GrCertificate } from "react-icons/gr";
import teleIcon from '../../assets/images/Telemedicine.svg';
import StethIcon from '../../assets/images/stethoscope.svg';
import { ServicesTab } from '../../shared/constants';
import { FaUsersCog } from "react-icons/fa";
import AvailableDays from './availableDays';

const MedicalService = ({ userData, practitionerrole, selectedbranch, setOpenShedule }) => {
  return (
    <div class="will-change-auto hover:will-change-scroll">
      <Paper sx={{ px: 2, py: 1 }}>
        <Grid className='flex gap-3 items-center text-base'> <span style={{ color: '#205072' }} className='text-[18px] font-semibold  '>Consult Doctor {userData?.name?.[0]?.text}</span> </Grid>
        <Grid className='pl-7 px-3 '>
          <Grid className='text-sm pt-1'>Out Patient Consultation</Grid>
          <Grid container justifyContent={"space-between"} spacing={2} className='px-2 pt-1'>
            <Grid item xs={9}>
              <Stack spacing={0.5}>
                <Typography sx={{ color: "#000000", fontSize: 14 }} className="text-sm font-medium flex gap-1 items-center" >
                  <LocationOnOutlined sx={{ fontSize: "15px", color: "#0F21B1", mr: "5px" }} />
                  Available at
                  <span className="text-sm" style={{ color: "#0F4BBE" }} >{selectedbranch?.name} </span>
                </Typography>
                <div className="flex gap-4" >
                  <div className="font-medium pt-2 items-center gap-1">
                    <EventAvailable sx={{ fontSize: "18px", color: "#4B4C52", mr: "5px" }} />
                    Available
                  </div>
                  <div>
                    <AvailableDays timingInfo={practitionerrole?.resource?.availableTime} />
                  </div>
                </div>
                {/* <Typography sx={{ color: "#000000", fontSize: 14 }} className="text-sm font-medium flex gap-1 items-center">
                  <AccessTimeIcon sx={{ fontSize: "15px", color: "#4B4C52", mr: "5px" }} />
                  {val.timings}
                  <span className="text-sm" style={{ color: "#0F4BBE" }} >Mon-Sat 8:00am-9:30 </span>
                </Typography> */}
              </Stack>
            </Grid>
            <Grid item xs={3} textAlign={"right"}>
              <Button variant="outlined" startIcon={<EventAvailableIcon />}
                style={{
                  backgroundColor: "#EAF5FD", color: "#205072", textTransform: "capitalize",
                  borderRadius: "10px",
                }}
                sx={{
                  width: { xs: "130px", sm: "130px", md: "140px", lg: "160px", },
                  height: "30px",
                  fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px", },
                  justifyContent: 'space-between',
                }}
                onClick={() => setOpenShedule(true)}
              >
                Schedule Visit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ px: 2, py: 1, mt: 1 }}>
        <Grid className='pl-7 px-3 ' >
          <Grid className='text-sm '>Telemedicine</Grid>
          <Grid container justifyContent={"space-between"} spacing={2} className='px-2 pt-1'>
            <Grid item xs={9}>
              <Stack spacing={0.5}>
                <Typography sx={{ color: "#000000", fontSize: 14 }} className="text-sm font-medium flex gap-1 items-center">
                  <EventAvailable sx={{ fontSize: "15px", color: "#4B4C52", mr: "5px" }} />
                  Available
                  <span className="text-sm" style={{ color: "#0F4BBE" }} >week days </span>
                </Typography>
                <Typography sx={{ color: "#000000", fontSize: 14 }} className="text-sm font-medium flex gap-1 items-center">
                  <AccessTimeIcon sx={{ fontSize: "15px", color: "#4B4C52", mr: "5px" }} />
                  {/* {val.timings} */}
                  <span className="text-sm" style={{ color: "#0F4BBE" }} >Mon-Sat 8:00am-9:30 </span>
                </Typography>
                <Typography sx={{ color: "#000000", fontSize: 13 }} className="text-sm font-medium flex gap-1 items-center">
                  Note : Telemedicine is online consultation preferable to patients, seeking second opinion, already examined by a qualified practitioner, and for follow-up review. Do not choose this in case of emergency and acute cases.
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={3} textAlign={"right"}>
              <Button variant="outlined" startIcon={<img src={teleIcon} width={20} height={20} alt="tele" />}
                style={{ backgroundColor: "#fff", color: "#205072", textTransform: "capitalize", borderRadius: "10px", }}
                sx={{
                  width: { xs: "130px", sm: "130px", md: "140px", lg: "160px", },
                  height: "30px",
                  fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px", },
                  justifyContent: 'space-between',
                }}
              >
                Consult Online
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ px: 2, py: 1, mt: 1 }}>
        <Grid container className="text-base  font-semibold pt-2 pb-1" style={{ color: '#205072' }}><MedicalServicesIcon /> &nbsp; Healthcare Services</Grid>
        <Grid className='pl-7 pt-1'>
          {ServicesTab?.slice(0, 5)?.map((item) =>
            <Grid key={item?.title} className="text-sm pt-1">{item?.title}</Grid>
          )}

        </Grid>
      </Paper>
      <Paper sx={{ px: 2, py: 1, mt: 1 }}>
        <Grid container className="text-base  font-semibold pt-2 pb-1" style={{ color: '#205072' }}><LiaStethoscopeSolid fontSize={24} /> &nbsp; Skills & Expertise</Grid>
        <Grid className='pl-7 pt-1'>
          <Grid className='text-sm font-semibold'>Peadtric Advanced Life Support (PALS)</Grid>
          <Grid className='text-xs flex pt-1 items-center' style={{ color: '#707070' }}><GrCertificate style={{ color: '#205072' }} /> &nbsp;PALS, CME- Apollo Hospitals, Hyderabad</Grid>
          <Grid className='text-xs pt-1' style={{ color: '#707070' }}>Paramitha Hospitals, Hyderabad</Grid>
          <Grid className='text-sm font-semibold flex items-center pt-1'><FaUsersCog /> &nbsp; 02 Endorsements</Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default MedicalService