import { Box, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { Call, Directions, Email, Place, VerifiedUser } from "@mui/icons-material";
import ContainedButton from "../atoms/commonbutton";
import axios from "axios";
import BranchUrl from "../../services/api_branch_url";

const FacilityInformation = ({ qr, orgInfo }) => {
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
  const [mapCenter, setMapCenter] = useState({ lat: "", lng: "" });
  const [orgData, setOrgData] = useState('');
  const [loc, setLoc] = useState({ lat: "", lng: "" });
  const baseurl = BranchUrl();
  const branchName = useSelector((state) => state.branch.branch);
  const [locationBranch, setLocationBranch] = React.useState('');

  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  useEffect(() => {
    setLocationBranch(branchName);
  }, [branchName]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedbranch?.id) {
        try {
          const res = await axios.get(`${baseurl.fhir_tenant_orgId}Location`, {
            headers: {
              'X-FHIR-TENANT-ID': 'parimitha',
              'Realm': 'parimitha',
              'X-FHIR-DSID': 'fhirparimitha'
            }
          });

          // Check if the response is valid and contains the expected data
          if (res.data.entry?.length > 0) {
            setOrgData(res.data.entry[0].resource);  // Only set the data if it exists
          } else {
            console.log('No data found');
          }
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      } else {
        console.log('Selected branch or its ID is missing');
      }
    };

    fetchData();
  }, [selectedbranch]);

  useEffect(() => {
    // Update map center and location when orgData changes
    if (orgData && orgData.position) {
      const latitude = parseFloat(orgData.position.latitude);
      const longitude = parseFloat(orgData.position.longitude);

      setLoc({ lat: latitude, lng: longitude });
      setMapCenter({ lat: latitude, lng: longitude });

    }
  }, [orgData]);
  // console.log(orgData, "orgdaaaaaaaaata")


  return (
    <div >
      <Box sx={{ mt: 12 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Grid item container spacing={2}>
              <Grid item sm={8} md={8} >
                <Box sx={{ display: 'flex', color: '#2E3B43', fontSize: 20, fontWeight: 500, alignItems: 'center', gap: 1 }}>
                  <VerifiedUser sx={{ color: '#3CB90E' }} />
                  <div>Verified Healthcare Provider</div>

                </Box>
                <div className="mt-4">
                  <span style={{ color: '#2E3B43', fontSize: 15, fontWeight: 500 }}> HFR ID : </span>
                  {
                    orgInfo?.identifier?.find(id => id.system === "https://hfrId/")?.value || '-'
                  }
                  <span>

                  </span>
                </div>
                <p className="text-sm mt-1 font-normal" style={{ color: '#4D4D4D' }}>Scan the QR code to find this Doctor in other Apps & Hospital Website</p>

                <div className="mt-6 text-xl font-medium" style={{ color: '#2E3B43' }}>
                  Contact Details
                </div>
                <div className="mt-4 font-normal" style={{ color: '#4D4D4D' }}>
                  {selectedbranch?.name}
                </div>
                <div className="flex items-center font-normal gap-2 mt-2" style={{ color: '#4D4D4D' }}>
                  <Call />
                  <div className="text-sm font-normal">{orgInfo?.telecom?.[0]?.value || "+91 040 2223 09888"}</div>
                </div>
                <div className="flex items-center font-normal gap-2 mt-4" style={{ color: '#4D4D4D' }}>
                  <Email />
                  <div className="text-sm font-normal">{orgInfo?.telecom?.[1]?.value || "support@paramithahospitals.com"}</div>
                </div>
                <div className="flex items-center  gap-2 mt-4" style={{ color: '#4D4D4D' }}>
                  <Place />
                  <div className="text-sm font-normal pt-4 text-wrap ">
                    {orgData?.address?.line?.[0] ? `${orgData?.address?.line?.[0]}, ` : ''}
                    {orgData?.address?.district ? `${orgData?.address?.district}, ` : ''}
                    {orgData?.address?.state ? `${orgData?.address?.state}, ` : ''}
                    {orgData?.address?.postalCode ? `${orgData?.address?.postalCode},` : ''}
                  </div>
                </div>
              </Grid>
              <Grid item sm={4} md={4}>
                {/* <Box style={{ width: '100px', height: '100px' }} className="border border-inherit">{
                // qr && <QRcode id="myqr" value={qr} size={100} />
              }</Box> */}
              </Grid>
            </Grid>


            {/* <Divider sx={{ mt: 4, mr: '50px' }} /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card>
              <Box >
                {
                  mapCenter &&
                  <LoadScript googleMapsApiKey={process.env.REACT_APP_LOCATION_KEY}>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={mapCenter}
                      zoom={20}
                    >
                      <Marker position={mapCenter} />
                    </GoogleMap>
                  </LoadScript>
                }
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6}>
            {/* <div className="text-center mt-1 font-normal" style={{ color: '#4D4D4D' }}>Connect on social media</div> */}
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <div className="flex justify-between items-center md:justify-center gap-2 mt-1">
              {/* <FaXTwitter style={{ width: 24, height: 24, color: '#E44190' }} />
              <CiInstagram style={{ width: 24, height: 24, color: '#E44190' }} />
              <RiYoutubeLine style={{ width: 24, height: 24, color: '#E44190' }} />
              <AiOutlineFacebook style={{ width: 24, height: 24, color: '#E44190' }} />
              <FaLinkedin style={{ height: 24, width: 24, color: '#E44190' }} /> */}

            </div>
          </Grid>
          <Grid item xs={12} md={6} >
            <div className="flex  md:flex-row items-center gap-3 md:justify-end mt-1 md:m-0">
              {/* <OutlinedButton text=" Write a Review" width={{ xs: '170px', md: "200px", lg: '200px' }} height={{ xs: "44px", md: "47px" }} borderColor="#E54290" color="#E54290" borderRadius="10px" fontSize={{ xs: '13px', md: '16px' }} /> */}
              <a
                style={{ textDecoration: 'none' }}
                target="_blank"
                href={`https://www.google.com/maps?q=${mapCenter?.lat},${mapCenter?.lng}`}
              >  <ContainedButton text="Get Directions" height={{ xs: "44px", md: "47px" }} bgcolor="#E54290" width={{ xs: '170px', md: "200px", lg: '200px' }} borderRadius="10px" sx={{ display: 'flex', justifyContent: 'start', gap: 2 }} startIcon={<Directions sx={{ width: { xs: '20px', md: 32 }, height: { xs: '20px', md: 32 } }} />} fontSize={{ xs: '13px', md: '16px' }} /></a>
            </div>
          </Grid>
        </Grid>

      </Box >
    </div>

  );
};

export default FacilityInformation;
