import React, { useCallback, useEffect, useState } from 'react'
import { ExpandLess, ExpandMore, LocationOnOutlined, PersonAddAltOutlined, QrCode2 } from '@mui/icons-material'
import { Autocomplete, Box, Button, Card, Chip, CircularProgress, DialogActions, DialogContent, Divider, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { RiVerifiedBadgeFill } from 'react-icons/ri'
import { CustomTextField } from '../atoms/TextField'
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton'
import { Commoncalender } from '../atoms/commoncalender'
import { localstore } from '../localstore/localstore'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getPatientdatafromibm } from '../../redux/actions/actions'
import dayjs from 'dayjs'
import URL from '../../services/api_url'
import { useNavigate } from 'react-router-dom'
import BranchUrl from '../../services/api_branch_url'
import { debounce } from 'lodash'
import { calculateAge } from '../atoms/calculateAge'

const appointmentRoles = [
    {
        system: "http://terminology.hl7.org/CodeSystem/v2-0276",
        code: 'ROUTINE',
        display: "Routine appointment - default if not valued"
    },
    {
        system: "http://terminology.hl7.org/CodeSystem/v2-0276",
        code: 'CHECKUP',
        display: " A routine check-up, such as an annual physical"
    },
    {
        system: "http://terminology.hl7.org/CodeSystem/v2-0276",
        code: 'FOLLOWUP',
        display: "A follow up visit from a previous appointment"
    },
    // {
    //     system: "http://terminology.hl7.org/CodeSystem/v2-0276",
    //     code: 'EMERGENCY',
    //     display: "Emergency appointment"
    // },
]

function HealthCareAppointment(props) {
    const { phoneNumber, selectedSlot, startDate, endDate, userData, userRole, id, handleCancel, deptData, serviceData } = props

    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const patientdata = useSelector((state) => state.paramitha.patientDetails)
    const selectedBranchId = selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`

    const ibmId = localstore.getIbmId()
    const usertoken = localstore.getToken()
    const urls = BranchUrl()
    const [selectreason, setSelectreason] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [contains, setContains] = useState([]);
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [gender, setGender] = useState('')
    const [birth, setBirth] = useState('');
    const [fullName, setFullName] = useState('')
    const [patientValue, setPatientValue] = useState(null);
    const [patientOpen, setPatientOpen] = useState(false);
    const [patientBlur, setPatientBlur] = useState(false);
    const [expand, setExpand] = useState(false)
    const [errors, setErrors] = useState({});
    const [appointmentType, setAppointmentType] = useState("");
    const [patientId, setPatientId] = useState('')
    const [appointmentId, setAppointmentId] = useState(null)
    const [registerDetails, setRegisterDetails] = useState('')
    const [patientInfo, setPatientInfo] = useState([]);

    const [tokenNumber, setTokenNumber] = useState("");
    const [patientDetails, setPatientDetails] = useState([])
    // console.log(patientDetails, "patientvalue")
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [showError, setShowError] = useState({});
    const [alertOpen, setAlertOpen] = useState(false)
    const [age, setAge] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(10); // Initial count set to 10
    const [scrollNum, setScrollNum] = useState(0)
    // console.log(patientDetails, "patientDetails")
    // console.log(patientValue, "valuepatient")
    // console.log(userData, "userrrrrrr")
    // console.log(appointmentType, "PPOIN")
    const handleAppointmentChange = (event, value) => {
        setAppointmentType(value);
        setShowError({ ...showError, appointmentType: '' });
    };
    const handleOnChangeDate = (newValue) => {
        setBirth(newValue?.format("YYYY-MM-DD"))
        setErrors('')
    }
    const handleChangeToken = (e) => {
        setTokenNumber(e.target.value?.replace(/\D/g, "")?.slice(0, 10))
    }
    const paramswithToken = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha',
        'Authorization': `Bearer ${usertoken}`
    }

    const paramswithoutToken = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha'
    }

    useEffect(() => {
        if (appointmentRoles && appointmentRoles.length > 0) {
            setAppointmentType(appointmentRoles[0]);
        }
    }, [appointmentRoles]);

    const getpatientDetailsPhone = async () => {
        try {

            if (ibmId && patientdata?.link) {
                const patients = patientdata?.link?.map((patient) => patient?.target?.reference);

                // Function to call the API for each patient
                const fetchPatientData = async (patientReference) => {
                    try {
                        const response = await axios.get(`${urls.person_tenant_orgId}${patientReference}`, {
                            headers: {
                                'X-FHIR-TENANT-ID': 'parimitha',
                                'X-FHIR-DSID': 'fhirparimitha',
                                'Realm': 'parimitha',
                                'Authorization': `Bearer ${usertoken}`
                            }
                        });
                        return response.data;
                    } catch (error) {
                        console.error(`Error fetching data for ${patientReference}:`, error);
                        return null; // Return null if the API call fails
                    }
                };

                // Call the API for all patient references and store the results in an array
                const fetchAllPatientsData = async () => {
                    try {
                        const results = await Promise.all(patients.map(fetchPatientData));
                        const validResults = results.filter(result => result !== null); // Filter out failed requests
                        setPatientDetails(validResults);
                    } catch (error) {
                        console.error("Error fetching all patient data:", error);
                    }
                };

                fetchAllPatientsData();
            } else if (phoneNumber) {
                const response = await axios.get(`${urls.fhir_tenant_orgId}Patient?_count=1000&phone=${phoneNumber}`, {
                    headers: paramswithoutToken
                });
                setPatientDetails(response?.data?.entry);
            } else {
                console.log('Both IBM ID and phone number are missing.');
            }
        } catch (error) {
            console.error('Error fetching patient details:', error);
        }
    };
    const getpatientRegisterDetails = () => {
        if (ibmId) {
            axios.get(`${urls.person_tenant_orgId}Patient/${patientId}`, {
                headers: paramswithToken
            }).then(res => {
                setRegisterDetails(res?.data)
            }).catch(error => {
                // console.log(error)
            })
        } else {
            axios.get(`${urls.fhir_tenant_orgId}Patient/${patientId}`, {
                headers: paramswithoutToken
            }).then(res => {
                setPatientDetails(res?.data?.entry)
            })
        }
    }


    useEffect(() => {
        getpatientDetailsPhone()
        if (patientId) {
            getpatientRegisterDetails()
        }
    }, [patientdata, phoneNumber])

    useEffect(() => {
        if (usertoken && ibmId) {
            dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
        }

    }, [usertoken, ibmId])


    const fetchSearchResults = async (value, currentPage, currentCount) => {
        if (value) {
            try {
                setLoading(true);
                const res = await axios.get(`${URL.relationship}ValueSet/$expand`, {
                    params: {
                        url: 'http://hl7.org/fhir/ValueSet/encounter-reason',
                        filter: value,
                        page: currentPage,
                        count: currentCount,
                    },
                });
                const newResults = res?.data?.expansion?.contains || [];
                setSearchResults(prevResults => {
                    const existingCodes = new Set(prevResults.map(result => result.code));
                    const filteredNewResults = newResults.filter(result => !existingCodes.has(result.code));
                    return [...prevResults, ...filteredNewResults];
                });
                setScrollNum(res?.data?.expansion?.total);
                setHasMore(newResults.length > 0); // If less than requested items, no more data
                setPage(prevPage => prevPage + 1);
                setCount(prevCount => prevCount + 10); // Increase count by 10 for each new page
            } catch (error) {
                console.log(error);
                setSearchResults([]); // Clear search results or handle error state

            } finally {
                setLoading(false);
            }
        }
    };

    const debouncedFetchSearchResults = useCallback(debounce((value, currentPage, currentCount) => {
        fetchSearchResults(value, currentPage, currentCount);
    }, 1000), []);

    const handleInputChange = (event, value) => {
        setSearchTerm(value);
        setPage(1); // Reset page number on new search
        setCount(10);
        debouncedFetchSearchResults(value, 1, 10);
        setSearchResults([]);
    };

    const handleSelectOption = (event, option) => {
        if (option && option.display) {
            setSelectedOptions((prevOptions) => [...prevOptions, option]);
        }
    };
    const handleDeleteChip = (option) => {
        setSelectedOptions(selectedOptions.filter(item => item !== option));
    }

    const handleSubmitPatient = async (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!fullName.trim()) {
            newErrors.fullName = "Full Name is required";
        }
        if (!gender) {
            newErrors.gender = "Gender is required";
        }
        if (!birth) {
            newErrors.birth = "Date Of Birth is required";
        }

        let patientResource =
        {
            "resourceType": "Patient",
            "active": true,
            "address": [],
            "birthDate": birth || '',
            "communication": [],
            "gender": gender || '',
            "identifier": [],
            "name": fullName ? [
                {
                    "use": "official",
                    "text": fullName,
                    "given": [
                        fullName
                    ],
                    // "family": "k"
                }
            ] : [],
            "photo": [],
            managingOrganization: {
                "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`
            },
            "telecom": [
                {
                    "use": "mobile",
                    "value": ibmId ? patientdata?.telecom?.[0]?.value : phoneNumber,
                    "system": "phone"
                }
            ]
        };

        if (Object.keys(newErrors).length === 0) {
            // console.log('Form submitted successfully:', { fullName, gender, birth });

            const newPatient = {
                text: fullName,
                given: fullName,
                gender: gender,
                birthDate: birth,
            };


            setLoading(true);
            try {
                if (ibmId) {

                    const patientRes = await axios.post(`${urls.person_tenant_orgId}Patient`, patientResource, {
                        headers: paramswithToken
                    });

                    // Creating the new link object

                    const res = await axios.get(`${urls.person_tenant_orgId}Person/${ibmId}`, {
                        headers: paramswithToken
                    });

                    const personDetails = res.data
                    const newLink = {
                        target: {
                            reference: `Patient/${patientRes.data}` // Assuming the response contains the new patient's ID
                        }
                    };

                    // Check if the link array exists; if not, initialize it
                    if (!personDetails.link) {
                        personDetails.link = [];
                    }

                    // Add the new link to the link array
                    const updatedLinks = [...personDetails.link, newLink];

                    // Update the patientdata with the new links array
                    const updatedPatientData = {
                        ...personDetails,
                        link: updatedLinks
                    };

                    // Making the PUT request to update the Person resource with the new link
                    await axios.put(`${urls.person_tenant_orgId}Person/${ibmId}`, updatedPatientData, {
                        headers: paramswithToken
                    })
                        .then(res => {
                            // Dispatching an action to get the updated patient data
                            dispatch(getPatientdatafromibm(urls, usertoken, ibmId));

                            // Handling UI updates after successful operations
                            setPatientId(patientRes.data); // Ensure this is setting the ID
                            setPatientInfo([...patientInfo, patientResource]);
                            setPatientValue(newPatient);
                            setPatientOpen(false);
                            setPatientBlur(true);
                        })
                        .catch(err => {
                            console.error('Error updating patient data:', err); // Handle potential errors
                        });
                } else {
                    const res = await axios.post(`${urls.fhir_tenant_orgId}Patient`, patientResource, {
                        headers: paramswithoutToken
                    });

                    // Access Location header
                    // const location = res.headers['location'];
                    // const pId = location.split('/').slice(-3, -2)[0];
                    const pId = res.data
                    setPatientId(pId);

                    setPatientInfo([...patientInfo, newPatient]);
                    setPatientValue(newPatient);
                    setPatientOpen(false);
                    setPatientBlur(true);
                }
            } catch (error) {
                console.log(error);
                setAlertmessage(error.message);
                setAlertstatus('error');
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(newErrors);
        }
    };


    const handleCancelFinal = () => {
        setSelectreason(null);
        setFullName("");
        setGender("");
        setBirth("");
        setPatientValue(null);
        setPatientOpen(false);
        setExpand(false);
        setErrors({});
        setAppointmentType("");
        setPatientId('');
        setRegisterDetails('');
        setPatientInfo([]);
        setTokenNumber("");
        setPatientDetails([]);
        setSearchTerm('');
        setSelectedOptions([]);
        setSearchResults([]);
        handleCancel()
    }


    console.log(patientValue, "patientvalue")
    const appointmentBookingFn = async () => {
        let newErrors = {};

        // if (!appointmentType) {
        //     newErrors.appointmentType = "Please select an appointment type.";
        // }

        if (!patientValue) {
            newErrors.patientValue = "Please select a patient.";
        }

        setShowError(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const data = {
                "resourceType": "Appointment",
                "status": "proposed",
                "reasonCode": selectedOptions.map(option => ({
                    "coding": [
                        {
                            "system": option.system || '',
                            "code": option.code || '',
                            "display": option.display || ''
                        }
                    ]
                })),
                "participant": [
                    {
                        "actor": {
                            "reference": `Patient/${ibmId ? patientId || patientValue?.id : patientId || patientValue?.resource?.id || ''}`,
                            "display": patientValue?.text || patientValue?.resource?.name?.[0]?.text
                        },
                        "required": "required",
                        "status": "accepted"
                    },
                    {
                        "actor": {
                            "reference": `HealthcareService/${serviceData?.id || ''}`,
                            "display": serviceData?.name || ''
                        },
                        "required": "required",
                        "status": "accepted"
                    }
                ],

                "requestedPeriod": [
                    {
                        "start": startDate || '',
                        "end": endDate || ''
                    }
                ]
                // "requester": {
                //     "reference": `Practitioner/${userData?.id || ''}`  // Uncomment if requester is needed
                // },
                // "category": [
                //     {
                //         "coding": [
                //             {
                //                 "system": "http://hl7.org/fhir/ValueSet/servicerequest-category",
                //                 "code": "409971007",  
                //                 "display": "Emergency medical services"
                //             }
                //         ]
                //     }
                // ],
                ,
                // "performer": [
                //     {
                //         "reference": `Organization/${selectedBranchId || ''}`
                //     }
                // ],
                // "priority": "routine",
                // "reasonCode": selectedOptions.map(option => ({
                //     "coding": [
                //         {
                //             "system": option.system || '',  
                //             "code": option.code || '',
                //             "display": option.display || ''
                //         }
                //     ]
                // })),
                // "participant": [
                //     serviceData
                //         ? {
                //             "actor": {
                //                 "reference": `HealthcareService/${serviceData?.id || ''}`, 
                //                 "display": serviceData?.name || ''
                //             },
                //             "required": "required",
                //             "status": "accepted"
                //         }
                //         : {
                //             "actor": {
                //                 "reference": `Location/${deptData?.id || ''}`,  
                //                 "display": deptData?.name || ''
                //             },
                //             "required": "required",
                //             "status": "accepted"
                //         }
                // ],


            };


            if (!ibmId && !usertoken) {

                try {
                    await axios.post(`${urls.fhir_tenant_orgId}Appointment`, data, {
                        headers: {
                            'X-FHIR-TENANT-ID': 'parimitha',
                            'X-FHIR-DSID': `fhirparimitha`,
                            'realm': 'parimitha',
                        }
                    });

                    alert('Appointment Request Sent Successfully');
                    handleCancelFinal()
                    // navigate('/');
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    await axios.post(`${urls.person_tenant_orgId}Appointment`, data, {
                        headers: {
                            'X-FHIR-TENANT-ID': 'parimitha',
                            'X-FHIR-DSID': `fhirparimitha`,
                            'realm': 'parimitha',
                            'Authorization': `Bearer ${usertoken}`
                        }
                    });

                    alert('Appointment Request Sent Successfully');
                    handleCancelFinal()
                    // navigate('/');
                } catch (error) {
                    console.log(error);
                }
            }
        };



    };

    return (
        <div>
            <DialogContent>
                <div >
                    <Grid container >
                        <Grid item xs={12} md={7}>
                            <div className='text-base font-medium text-sm md:text-lg '>Your Details Found at {selectedbranch?.name} </div>
                            <div className='text-sm flex gap-2 items-center fntsz-15 mt-1 font-medium'><RiVerifiedBadgeFill /> Mobile Number Verified</div>
                            <div className='text-sm flex gap-2 items-center fntsz-14 mt-0.5 font-medium ml-6'>{ibmId ? patientdata?.telecom?.[0]?.value : phoneNumber}</div>

                            <div className='text-sm mt-2'>Pediatrics OP Consultation</div>
                            <Typography sx={{ color: "#000000", fontSize: "14px", }} className="text-sm font-medium flex pt-1 items-center" >
                                <LocationOnOutlined sx={{ fontSize: "18px", color: "#0F21B1", mr: "2px" }} />
                                Available at <span className="text-sm pl-2" style={{ color: "#0F4BBE" }} > {selectedbranch?.name} </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <div className='font-semibold text-medium pb-2  sm:mt-2'>Booking Consultation for </div>
                            <div className='border border-slate-400 rounded-md '>
                                <div className='flex items-center justify-between p-1.5 px-2 cursor-pointer' onClick={() => {
                                    setExpand(!expand)
                                    setPatientOpen(false)
                                }}>
                                    <div>
                                        <Typography>
                                            {
                                                ibmId ?
                                                    patientValue ? (
                                                        <>
                                                            <div className='font-medium'>{patientValue?.text || `${patientValue?.name?.[0]?.given}${patientValue?.name?.[0].family || ''}`}</div>
                                                            <div className='fntsz-13'>{patientValue?.birthDate ? `${calculateAge(patientValue?.birthDate)} years` : '' || patientValue?.birthDate ? `${calculateAge(patientValue?.birthDate)} years` : ''} | {patientValue?.gender || patientValue?.gender}</div>
                                                        </>

                                                    ) : (
                                                        "Select Patient"
                                                    ) : patientValue ? (
                                                        <>
                                                            <div className='font-medium'>{patientValue?.text || `${patientValue?.resource?.name?.[0]?.given}${patientValue?.resource?.name?.[0].family || ''}`}</div>
                                                            <div className='fntsz-13'>{patientValue?.birthDate ? `${calculateAge(patientValue?.birthDate)} years` : '' || patientValue?.resource?.birthDate ? `${calculateAge(patientValue?.resource?.birthDate)} years` : ''} | {patientValue?.gender || patientValue?.resource?.gender}</div>
                                                        </>

                                                    ) : (
                                                        "Select Patient"
                                                    )
                                            }

                                        </Typography>

                                    </div>
                                    {expand ? <ExpandLess /> : <ExpandMore />}

                                </div>
                                {
                                    expand && <>
                                        <Divider sx={{ mt: "5px" }} />
                                        <div className='p-1.5 px-2'
                                            style={{
                                                overflowY: 'scroll', maxHeight: "40vh", '&::-webkit-scrollbar': {
                                                    display: 'none',
                                                }
                                            }}
                                        >
                                            {
                                                ibmId ?

                                                    patientDetails?.map((patient, index) =>
                                                        <div className='cursor-pointer' onClick={() => {
                                                            setPatientValue(patient)
                                                            setPatientBlur(true)
                                                            setExpand(false)
                                                            setShowError({ ...showError, patientValue: '' })

                                                        }}>
                                                            <div className='fntsz-14'>{patient?.name[0]?.given} {patient?.name[0]?.family}</div>
                                                            <div className='fntsz-13'>{patient?.birthDate ? `${calculateAge(patient?.birthDate)} years` : ''} | {patient?.gender}</div>
                                                            <Divider sx={{ m: "8px 0px" }} />
                                                        </div>
                                                    ) : patientDetails?.map((patient, index) =>
                                                        <div className='cursor-pointer' onClick={() => {
                                                            setPatientValue(patient)
                                                            setPatientBlur(true)
                                                            setExpand(false)
                                                            setShowError({ ...showError, patientValue: '' })

                                                        }}>
                                                            <div className='fntsz-14'>{patient?.resource?.name[0]?.given} {patient?.resource?.name[0]?.family}</div>
                                                            <div className='fntsz-13'>{patient?.resource?.birthDate ? `${calculateAge(patient?.resource?.birthDate)} years` : ''} | {patient?.resource?.gender}</div>
                                                            <Divider sx={{ m: "8px 0px" }} />
                                                        </div>
                                                    )


                                            }

                                            < div className='flex gap-2 cursor-pointer' onClick={() => {
                                                setPatientOpen(true)
                                                setExpand(false)
                                                setPatientValue('')
                                                setFullName('')
                                                setGender('')
                                                setBirth('')
                                                setPatientBlur(false)

                                            }}><PersonAddAltOutlined />
                                                <div>
                                                    <div className='fntsz-14'>Register New Person
                                                    </div>
                                                    <div className='fntsz-12'>add a family member with this Mobile number
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    </>
                                }
                            </div>
                            {showError.patientValue && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{showError.patientValue}</Typography>}

                            {/* <div onClick={() => setPatientOpen(true)} style={{ color: '#1B5775' }}>Add Patient</div> */}
                        </Grid>
                        {
                            patientOpen &&
                            <Grid>
                                <Divider style={{ marginTop: '20px' }} />
                                <div className='font-semibold py-2'>Add Patient Details</div>
                                <Grid container spacing={2}>

                                    <Grid item xs={12} md={6.8} sx={{ pointerEvents: 'auto', opacity: 0.3 }}>
                                        <div className='md:pr-10'>
                                            <div className='flex items-center gap-6 font-normal '>
                                                <QrCode2 sx={{ fontSize: 100 }} />
                                                <p className='fntsz-15'>Scan this QR code from any Ayushmann Bharat Health Application , For Quick Registration & Verification</p>
                                            </div>
                                            <div className='font-medium py-2'>
                                                Input Token number to complete Registration
                                            </div>
                                            <CustomTextField placeholder="Token Number" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={tokenNumber} onChange={handleChangeToken} />
                                            <div className='mt-2 flex items-center justify-between'>
                                                <div></div>
                                                <OutlinedButton text="Verify Token" width="150px" height="33px" color="#1B5775" borderRadius="7px" borderColor="#195775" />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5.2}>

                                        <div className='font-medium py-1'>Full Name (Display Name)</div>
                                        <CustomTextField placeholder="Patient Name" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={fullName} onChange={(e) => {
                                            setFullName(e.target.value)
                                            setErrors('')
                                        }} error={errors.fullName}
                                        />

                                        {errors.fullName && <div className='fntsz-12 mt-0.5 text-red-500'>{errors.fullName}</div>}

                                        <div className='font-medium mt-1'>Gender</div>
                                        <Select
                                            displayEmpty
                                            fullWidth
                                            sx={{ '.MuiSelect-select': { padding: '10px' }, mt: "5px", borderRadius: '7px' }}
                                            value={gender}
                                            onChange={(e) => {
                                                setGender(e.target.value);
                                                setErrors({ ...errors, gender: '' }); // Clear gender error message on change

                                            }}
                                            placeholder='Select your Gender'
                                            renderValue={(selected) => {
                                                if (!selected) {
                                                    return <span>Select your Gender</span>;
                                                }
                                                return selected;
                                            }}
                                            error={errors.gender}
                                        >
                                            <MenuItem value={'male'}>Male</MenuItem>
                                            <MenuItem value={'female'}>Female</MenuItem>
                                            <MenuItem value={'other'}>Other</MenuItem>
                                        </Select>
                                        {errors.gender && <div className='fntsz-12 mt-0.5 text-red-500'>{errors.gender}</div>}

                                        <div className='font-medium mt-1'>Date of Birth</div>
                                        <div>
                                            <Commoncalender value={dayjs(birth)}
                                                onChange={handleOnChangeDate} maxDate={dayjs()} width="100%"
                                            />
                                        </div>
                                        {errors.birth && <div className='fntsz-12 mt-0.5 text-red-500'>{errors.birth}</div>}

                                        <div className='flex justify-between mt-2'  >
                                            <Button color='error' onClick={() => setPatientOpen((prev) => !prev)} sx={{ textTransform: 'capitalize', width: '120px', height: '33px' }}>Cancel</Button>
                                            <ContainedButton color='primary' width="120px" borderRadius="7px" height="33px" variant='contained' bgcolor="#1B5775" text="Submit" endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} onClick={handleSubmitPatient} /></div>
                                    </Grid>

                                </Grid>

                                <Grid container justifyContent={"space-between"} className='gap-10' mt={2} spacing={5} >

                                </Grid>
                                <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                            </Grid>
                        }
                    </Grid>
                </div>
                <Box style={{ pointerEvents: !patientBlur && 'none', opacity: !patientBlur && 0.4 }}>
                    {/* <Grid pt={2} container fullWidth >
                        <FormControl component="fieldset" fullWidth>
                            <div>Appointment Type</div>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={appointmentType}
                            // sx={{ display: 'flex', justifyContent: 'space-around' }} // Apply styles here

                            >
                                {
                                    appointmentRoles?.map((appointment, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={appointment}
                                            control={<Radio />}
                                            label={appointment.code.toLowerCase()} // Display code in lowercase
                                            sx={{ textTransform: 'capitalize', pr: { xs: 0, sm: 5, md: 7 } }}
                                            // sx={{ '.MuiFormGroup-root': { display: 'flex', justifyContent: 'space-between' } }}

                                            onChange={(event) => handleAppointmentChange(event, appointment)} // Pass the appointment object to the handler function

                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {showError.appointmentType && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{showError.appointmentType}</Typography>} */}

                    <Grid container >
                        <Grid item xs={12} sm={12} md={6}>
                            <div className='font-medium py-2'>Reason For Appointment</div>
                            <Autocomplete
                                id="state-select"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#59D8FA',
                                        },
                                    },
                                    ".MuiInputBase-root": {
                                        borderRadius: '7px',
                                    },
                                }}
                                options={searchResults}
                                size='small'
                                autoHighlight
                                // value={searchTerm}
                                onChange={(event, value) => handleSelectOption(event, value)}
                                onInputChange={(event, value) => handleInputChange(event, value)} // Ensure correct parameter passing
                                fullWidth
                                getOptionLabel={(option) => option?.display || ''} // Ensure option.display is accessed correctly
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                        {option.display}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder='Search Reason for Appointment'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                )}
                            />

                            <div className='mt-1 flex flex-wrap gap-1' >
                                {selectedOptions?.map(option => (
                                    <Chip
                                        key={option?.code}
                                        label={option?.display}
                                        onDelete={() => handleDeleteChip(option)}
                                    />
                                ))}
                            </div>

                        </Grid>
                        <Grid item md={6}></Grid>

                    </Grid>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Grid container justifyContent={"space-between"} px={3}>
                    <Button
                        color='error' sx={{ textTransform: 'capitalize' }} onClick={handleCancelFinal}>Cancel</Button>
                    <Button color='primary' variant='contained' sx={{ textTransform: 'capitalize', background: '#1B5775' }} onClick={appointmentBookingFn} >Request Appointment</Button>

                </Grid>
            </DialogActions>

            {/* <BookingAlert open={alertOpen} setAlertOpen={setAlertOpen} userData={userData} userRole={userRole} selectedSlot={selectedSlot} selectedbranch={selectedbranch} handleCancel={handleCancelFinal} /> */}
        </div >
    )
}

export default HealthCareAppointment;