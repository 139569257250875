import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Checkbox, DialogContent, FormControl, Grid, InputAdornment, OutlinedInput, Paper, TextField, Typography } from "@mui/material";
import "./branchhome.css";
import { makeStyles } from "@mui/styles";
import DoctorImg from '../../assets/images/calendar.svg';
import DoctorImg_wt from '../../assets/doctor/Find Doctor.svg';
import CareImg from '../../assets/images/URGENT CARE.svg';
import CareImg_wt from '../../assets/doctor/Uegetcare.svg';
import EmergencyImg from '../../assets/doctor/ambulance-icon.svg';
import TeleImg from '../../assets/images/consult online.svg';
import TeleImg_wt from '../../assets/doctor/Telemedicine.svg';
import PortalImg from '../../assets/images/login.svg';
import PortalImg_wt from '../../assets/doctor/login.png';
import EmergencyPopup from "./EmergencyPopup";
import UrgentCarePopup from "./UrgentcarePopup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPatientdatafromibm } from "../../redux/actions/actions";
import { localstore } from "../localstore/localstore";
import BranchUrl from "../../services/api_branch_url";
import hospitalBgImg from '../../assets/doctor/hscoverpic.jpg'
import HelpComponent from "../home/Help";
import TelemedicinePopup from "./TelemedicinePopup";
import { createInteractionData } from "../atoms/getLocations";
import { sendInteractionData } from "../doctors/webSocketManager";
import { handleLogout } from "../atoms/handlelogout";

const useStyles = makeStyles((theme) => ({
  heading1: {
    textAlign: "center",
    fontWeight: 500,
    color: "#1D5775",
    textTransform: "uppercase",
  },
  textcolor: {
    // color: "#1D5775",
    fontWeight: "500",
    // fontSize: "1.3vw",
  },
  bodycolor: {
    // color: "#898989",
    fontWeight: "lighter",
  },
  card: {
    borderRadius: "10px",
    cursor: "pointer",
    border: '0.10000000149011612px solid rgba(112, 112, 112, 0.2)',
    borderRadius: '12px',
    // opacity: 1,
    // backgroundColor:'#FDF3F7 !important',
    // transition: "background 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#E54290 !important",  // Change to your desired hover background color
      opacity: 1,
      color: 'white'
    },
  },
}));
const CardsData = [
  {
    title: "Find Doctor",
    description: "Schedule a Visit",
    imgSrc: DoctorImg,
    imgSrchover: DoctorImg_wt,
    url: '/doctors'
  },
  {
    title: "Urgent Care",
    description: "Immediate Consult",
    imgSrc: CareImg,
    imgSrchover: CareImg_wt,
  },
  {
    title: "Emergency",
    description: "Ambulance Services",
    imgSrc: EmergencyImg,
    imgSrchover: EmergencyImg,
  },
  {
    title: "Telemedicine",
    description: "Consult Online",
    imgSrc: TeleImg,
    imgSrchover: TeleImg_wt,
  },
  // {
  //   title: "Talk To Experts",
  //   description: "Get answers & Support",
  //   icon: <AddIcCallIcon sx={{ fontSize: 48, mr: "10px" }} />,
  //   imgSrc: "/images/login.svg",
  // },
  {
    title: "Patient Portal",
    description: "Digital Health Login",
    //   icon: <AddIcCallIcon sx={{ fontSize: 48, mr: "10px" }} />,
    imgSrc: PortalImg,
    imgSrchover: PortalImg_wt,
  },
];

const BranchImage = ({ pageId, orgPageData }) => {
  const Classes = useStyles();
  const [isEmergencyOpen, setIsEmergencyOpen] = useState(false);
  const [isUrgentCareOpen, setIsUrgentCareOpen] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [emergencyOpen, setEmergencyOpen] = useState(false)
  const [urgentCareOpen, setUrgentCareOpen] = useState(false)
  const [telemedicineOpen, setTelemedicineOpen] = useState(false)
  const branchName = useSelector((state) => state.branch.branch)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const usertoken = localstore.getToken()
  const ibmId = localstore.getIbmId()
  const pathId = window.location.pathname
  const pageTitle = "Paramitha Hospitals"
  const activity = "find doctor"
  const urls = BranchUrl()
  const handleSelectOpen = async (title, item) => {
    if (title === "Emergency") {
      setEmergencyOpen(true)
    } else if (title === 'Urgent Care') {
      setUrgentCareOpen(true)
    } else if (title === 'Telemedicine') {
      setTelemedicineOpen(true)
    } else if (title === 'Find Doctor') {
      // navigate(`${selectedbranch?.name}/doctors`)
      const interactionData = await createInteractionData(pathId, pageTitle, activity);
      sendInteractionData(interactionData);
      navigate(branchName ? `doctors` : '/doctors')
    } else if (title === "Patient Portal") {
      // navigate(`${selectedbranch?.name}/doctors`)
      if (usertoken) {
        handleLogout()
      } else {
        navigate(branchName ? 'login' : '/login')

      }
    } else if (item?.url) {
      navigate(item?.url)
    }
  }

  useEffect(() => {
    if (ibmId && usertoken) {
      dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
    }
  }, [usertoken, ibmId])

  const handleCloseDialog = () => {
    setEmergencyOpen(false)
  }
  const handleUrgentCloseDialog = () => {
    setUrgentCareOpen(false);
  };
  const handleTeleCloseDialog = () => {
    setTelemedicineOpen(false);
  };
  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  }
  return (
    <div
      className='bgimgcontainer'
      style={{
        backgroundImage: `url(${orgPageData?.coverPhoto || hospitalBgImg})`
      }}
    >
      <div className='blackContainer'>
        <Box
          sx={{
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            p: { xs: '0px 10px 0px 12px', sm: '0px', md: '0px' },
          }}
        >
          <Typography
            level='h1'
            sx={{ color: '#E54290', textAlign: 'center', fontWeight: 500 }}
            fontSize={30}
          >
            How Can We Help?
          </Typography>
          <Grid
            container
            // rowSpacing={1}
            mt={2}
            justifyContent={{ xs: 'center', sm: 'center', lg: "space-between" }}
            // sx={{ p: { xs: "0px", sm: "10px 50px", md: "10px 80px" } }}
            sx={{ p: { xs: "0px", sm: "10px 20px", md: "10px 0px" }, display: { xs: 'flex' } }}

          >
            {CardsData.map((item, index) => (
              <Grid item xs={item?.title === "Patient Portal" ? 12 : 6} sm={6} md={3} lg={2.4} sx={{ p: "5px" }} key={index}>
                <>
                  <Card
                    variant="outlined"
                    sx={{
                      borderRadius: "10px",
                      cursor: 'pointer',
                      width: { xs: "100%", sm: "100%", md: 175 },
                      height: { xs: "100%", sm: "100%", md: 150 }
                    }}
                    className={Classes.card}
                    onClick={() => handleSelectOpen(item.title, item)}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <CardContent
                      sx={{
                        "&.MuiCardContent-root": {
                          p: "10px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: item?.title === "Patient Portal" ? 'row' : 'column', sm: 'column', md: 'column', lg: 'column'
                          },
                          justifyContent: {
                            xs: item?.title === "Patient Portal" ? 'start' : 'space-between', lg: 'space-between'
                          },
                          // alignContent: "center",

                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <div className="Classes.iconsize flex justify-center items-center" style={{ width: '60px', height: '60px' }}>
                          <img
                            src={index === hoveredCard ? item.imgSrchover : item.imgSrc}
                            alt=""
                            // width={35}
                            // height={35}
                            style={{
                              // marginRight: "8px",
                              Height:
                                item.title === "Emergency"
                                  ? "70px"
                                  : "60px",
                              width:
                                item.title === "Emergency"
                                  ? "130%" : (item.title === "Urgent Care" || item.title === "Telemedicine" || (item.title === "Patient Portal" && item.imgSrchover == PortalImg_wt)) ? '45px' : "50px",
                              maxWidth: item.title === "Emergency" && "130%"
                            }}
                          />
                        </div>
                        <Box
                          sx={{
                            height: { sm: "70px", md: "50px" },
                            justifyContent: 'center',
                            textAlign: 'center',
                            mb: {
                              xs: item?.title === "Patient Portal" ? '10px' : '0px', lg: '0px'
                            }
                          }}
                          pt={item.title === "Emergency" ? 2 : 2}
                        >
                          <Typography className={Classes.textcolor}>
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{ fontSize: { xs: '11px', md: "14px" }, color: index === hoveredCard ? '#ffffff !important' : '#898989' }}
                            className={Classes.bodycolor}
                          >
                            {item?.title === "Patient Portal" ? (usertoken ? "Digital Health Logout" : item.description) : item.description}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </>
                {item.title === "Emergency" &&
                  <EmergencyPopup open={emergencyOpen} handleClose={handleCloseDialog} />
                }
                {item.title === "Urgent Care" &&
                  <UrgentCarePopup open={urgentCareOpen} handleClose={handleUrgentCloseDialog} setIsUrgentCareOpen={setUrgentCareOpen} />
                }
                {item.title === "Telemedicine" &&
                  <TelemedicinePopup open={telemedicineOpen} handleClose={handleTeleCloseDialog} setTelemedicineOpen={setTelemedicineOpen} />
                }
              </Grid>
            ))}
          </Grid>
          {/* <Paper sx={{ backgroundColor: '#ffffff', p: 2 }}>
            <Typography variant='h1'>Doctor</Typography>
          </Paper> */}
        </Box>
      </div>
    </div>

  );
};

export default BranchImage;
