import { Grid } from '@mui/material';
import React from 'react'
import { PractitionerCard } from '../healthcare_services/PractitionerCard';

function PractitionerGroups({ practitioner }) {
    return (
        <div>
            <div className='font-semibold text-lg '>Meet Our Experts</div>
            <div className='pt-1 px-0 '>
                <Grid container columnSpacing={5}>
                    {practitioner?.map((val, index) => (
                        <Grid item xs={12} sm={6} key={index} md={6} lg={6} sx={{ p: { xs: "10px", sm: '10px', md: '10px', lg: '10px ' } }}>
                            <PractitionerCard val={val} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}

export default PractitionerGroups;