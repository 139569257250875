// URL.js
import { useSelector } from "react-redux";

const BranchUrl = () => {
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);

    const slot = `${process.env.REACT_APP_API_URL_SLOT}parimitha/`
    const paramithatenant = `${process.env.REACT_APP_API_URL_RESOURCE}parimitha/`
    const resource_tenant = `${process.env.REACT_APP_API_URL_RESOURCE}parimitha/resource/`
    const slot_tenant_orgId = `${process.env.REACT_APP_API_URL_SLOT}parimitha/${selectedbranch?.id ? selectedbranch.id : `${process.env.REACT_APP_PARAMITHA_ID}`}/`
    const tenantResource = `${process.env.REACT_APP_API_URL_v4}parimitha/resource/`
    const resourceTenant = `${process.env.REACT_APP_API_URL_v4}resource/parimitha/`
    const baseUrl = `${process.env.REACT_APP_API_URL_v4}resource/parimitha/${selectedbranch?.id ? selectedbranch.id : `${process.env.REACT_APP_PARAMITHA_ID}`}/`
    const mainBranchUrl = `${process.env.REACT_APP_API_URL_v4}parimitha/${selectedbranch?.id ? selectedbranch.id : `${process.env.REACT_APP_PARAMITHA_ID}`}/`
    const orgId = `${selectedbranch?.id ? selectedbranch.id : `${process.env.REACT_APP_PARAMITHA_ID}`}/`
    const tenant = `${process.env.REACT_APP_API_URL_v4}parimitha/`
    const urls = {

        // v1 api's
        paramitha_v1_practitioner: `${slot}Practitioner/getPractitionerRole`,
        paramitha_v1: `${slot}`,
        appointment: `${slot}`,
        patientDetails: `${slot}`,
        person_tenant_orgId: `${slot_tenant_orgId}`,
        patient_resource_tenant: `${resource_tenant}`,
        patient_tenant: `${paramithatenant}`,

        // v4 api
        fhir_resource_tenant: `${resourceTenant}`,
        fhir_auth: `${baseUrl}`,
        fhir_tenant_resource: `${tenantResource}`,
        fhir_tenant_orgId: `${mainBranchUrl}`,
        fhir_tenant: tenant,
        orgId: orgId
    };

    return urls; // Returning the URLs object directly
};

export default BranchUrl;
