import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
// import Styles from "./styles.module.css"
import "./headerstyles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HospitalLocation from "./HospitalFacilityLocation";
import SecondaryNavbar from "./secondrynavbar";
import axios from "axios";
import BranchUrl from "../../services/api_branch_url";
import SearchDoctors from "./searchDoctors";
import SearchComponent from "./search";


function ChooseBranch() {
    const spanRef = React.useRef();
    const navigate = useNavigate()
    const baseurl = BranchUrl()

    const isXsScreen = useMediaQuery("(max-width:900px)");
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const [orgInfo, setOrgInfo] = useState({});
    const [orgData, setOrgData] = useState({});
    useEffect(() => {
        if (selectedbranch?.id) {
            axios.get(`${baseurl.fhir_tenant}${selectedbranch?.id}/Organization`, {
                headers: {
                    "X-FHIR-TENANT-ID": 'parimitha',
                    "Realm": 'parimitha',
                    "X-FHIR-DSID": `fhirparimitha`,
                    "Cache-Control": "no-cache",
                },
            })
                .then((res) => {
                    setOrgInfo(res?.data);
                    // console.log(res?.data)
                })
                .catch((err) => console.log(err));
        }
    }, [selectedbranch]);
    useEffect(() => {
        if (selectedbranch?.id) {
            axios
                .get(
                    // `${baseurl.fhir_auth}Location?organization=${selectedbranch?.id}`,
                    `${baseurl.fhir_tenant_orgId}Location`,
                    {
                        headers: {
                            "X-FHIR-TENANT-ID": 'parimitha',
                            "Realm": 'parimitha',
                            "X-FHIR-DSID": `fhirparimitha`,
                        },
                    }
                )
                .then((res) => {
                    setOrgData(res?.data?.entry?.[0]?.resource);
                    // console.log(res?.data)
                })
                .catch((err) => console.log(err));
        }
    }, [selectedbranch]);

    const handleLogOut = () => {
        localStorage.removeItem("kctoken");
        localStorage.removeItem("IbmId");
        localStorage.removeItem("rctoken")
        localStorage.removeItem("userName")
        localStorage.removeItem("participantId")
        localStorage.removeItem("visitorId")
        localStorage.removeItem("pId")
        localStorage.removeItem("roomId")
        localStorage.removeItem("userId")
        localStorage.removeItem("patientName")
        navigate(
            selectedbranch?.name ? `/${selectedbranch?.name}/login` : "/login"
        );
    };


    return (
        <Box
            sx={{
                bgcolor: "#FDF3F7",
                // bgcolor: "#E44190",
                zIndex: "1000 !important",
                minHeight: { xs: 54, lg: 80 },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { xs: "0px 10px", lg: "0px 60px" }
            }}
        >
            <Grid
                container
                columnSpacing={3}
                sx={{ alignItems: 'center', ml: { xs: "", sm: "5px", md: '37px', lg: '37px' } }}
            >
                <Grid item xs={10} md={3.5} lg={3} >
                    <HospitalLocation />
                </Grid>
                <Grid item xs={1} md={6.5} lg={6} >
                    {!isXsScreen ?
                        <SecondaryNavbar
                            spanRef={spanRef}
                            transparent={false}
                            handleLogOut={handleLogOut}
                        /> :
                        <SecondaryNavbar
                            spanRef={spanRef}
                            transparent={false}
                            handleLogOut={handleLogOut} />
                    }
                </Grid>
                <Grid item xs={1} md={2} lg={3} >
                    {!isXsScreen &&
                        <SearchDoctors />}
                </Grid>
                {/* <Grid item md={2} lg={3} display="flex" justifyContent="center">
                    {selectedLocationName ? (
                        <div style={{ display: "flex", gap: 20 }}>
                            <a className="call" href={`tel:${orgInfo?.telecom?.[0]?.value}`}>
                                <IoIosCall color={"#fff"} fontSize={20} />
                            </a>
                            <a className="call" href={`mailto:${orgInfo?.telecom?.[1]?.value}`}>
                                <IoMdMail fontSize={20} color={"#fff"} />
                            </a>
                            <a
                                style={{ textDecoration: "none" }}
                                target="_blank"
                                href={`https://www.google.com/maps?q=${parseFloat(
                                    orgData?.position?.latitude
                                )},${parseFloat(orgData?.position?.longitude)}`}
                            >
                                <MdDirections fontSize={20} color={"#fff"} />
                            </a>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </Grid> */}
            </Grid>
        </Box>
    );
}

export default ChooseBranch;