import React from "react";
import Slider from "react-slick";
import { Box, CircularProgress, Typography } from '@mui/material';
import { PostData } from './PostData';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Healthcom.css';  // Import custom CSS for slider
import { HealthcomPosts } from "./healthcomPosts";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", fontSize: "20px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", fontSize: "20px" }}
            onClick={onClick}
        />
    );
}

function LatestUpdates({ posts, loading }) {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplaySpeed: 2000,

        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        customPaging: i => (
            <div
                className="custom-dot"
            />
        ),
        appendDots: dots => (
            <div style={{
                borderRadius: "10px", padding: "1px", marginTop: '10px',
            }}>
                <ul style={{ margin: "0px", padding: "0px", display: 'flex', justifyContent: 'center' }
                } > {dots}</ ul>
            </div>
        )
    };

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px", md: "0px" } }}>
            <Box sx={{ p: { xs: "5px", sm: "5px 20px", md: "5px 22px", lg: "5px 60px" }, mt: 1 }}>
                {/* <Box sx={{ p: { xs: "0px", sm: "10px 10px", md: "5px 10px" } }}> */}
                <Box>
                    <div style={{ position: "relative" }}>
                        {loading ? (
                            <CircularProgress sx={{ display: "block", mx: "auto", mt: 3 }} />
                        ) : (
                            posts && posts.length > 0 ? (
                                <div className="slider-container">
                                    <Slider {...settings}>
                                        {posts.map((post, index) => (
                                            <div key={index} className="post-slide">
                                                <HealthcomPosts feedData={post} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            ) : (
                                <Typography sx={{ textAlign: "center", mt: 3, height: "32px" }}>
                                    No posts available.
                                </Typography>
                            )
                        )}
                    </div>
                </Box>
            </Box>
            {/* </Box> */}
        </Box>
    );
}

export default LatestUpdates;
