import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Grid, Typography, Paper, Button, CardMedia, Divider, Stack } from "@mui/material";
import { EventAvailable, LocationOnOutlined } from "@mui/icons-material";
import LaunchIcon from '@mui/icons-material/Launch';
import { ServicesTab } from "../../shared/constants";
import StethIcon from '../../assets/images/stethoscope.svg';
import teleIcon from '../../assets/images/Telemedicine.svg';
import MaleDoctorImg from '../../assets/images/MaleDoctor-no-photo.svg';
import FemaleDoctorImg from '../../assets/images/FemaleDoctor-No Photo.svg';
import CommunicationImg from '../../assets/images/Communication-language.svg';
import { useNavigate } from "react-router-dom";
import RequestIcon from '../../assets/images/referral-3.svg'
import { PiFirstAidKit } from "react-icons/pi";
import { useSelector } from "react-redux";
import AvailableDays from "./availableDays";
import AppoinmentPopup from "./appointmentPopup";
import BranchUrl from "../../services/api_branch_url";
import { createInteractionData } from "../atoms/getLocations";
import { sendInteractionData } from "./webSocketManager";

const DoctorProfileCard = ({ val }) => {

    const [practitionerData, setPractitionerData] = useState({})
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const branchName = useSelector((state) => state.branch.branch);

    const [open, setOpen] = useState(false)
    const urls = BranchUrl()
    // console.log(selectBranchInfo)
    // console.log(val, "valllllllllll")
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(`${urls.fhir_tenant_orgId}${val?.resource?.practitioner?.reference}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`,
                'Realm': 'parimitha',
            }
        }).then((res) => {
            // console.log(res, "987654321")
            // setUserData(res?.data)
            setPractitionerData(res?.data || {})

        }).catch((err) => console.log(err))
    }, [])

    const practitionerId = val?.resource?.practitioner?.reference?.split('/')[1]
    const pathId = window.location.pathname
    // const practitionerIdentifier=practitionerData.identifier.
    const getIdentifierValue = (system) => {
        // Find the identifier object where system matches the given system
        const identifier = practitionerData?.identifier?.find(id => id.system === system);

        // If found, return the value, otherwise return null or empty string
        return identifier ? identifier?.value : null;
    };

    // Example Usage:
    const paramithaIdentifierValue = getIdentifierValue("https://paramithahospitals.com");

    const handleViewProfile = async () => {
        const pageTitle = val?.resource?.practitioner?.reference?.split('/')[0]
        let activity = "view profile"

        const interactionData = await createInteractionData(pathId, pageTitle, activity);
        sendInteractionData(interactionData);
        navigate(branchName ? `/${branchName}/doctor/${paramithaIdentifierValue}` : `/doctor/${paramithaIdentifierValue}`)
    }

    const handleConsultOnline = async () => {
        const pageTitle = val?.resource?.practitioner?.reference?.split('/')[0]
        let activity = "Consult Online"

        const interactionData = await createInteractionData(pathId, pageTitle, activity);
        sendInteractionData(interactionData);
    }

    const handleScheduleVisit = async () => {
        const pageTitle = val?.resource?.practitioner?.reference?.split('/')[0]
        const activity = "schedule visit"
        const interactionData = await createInteractionData(pathId, pageTitle, activity);
        sendInteractionData(interactionData);
        setOpen(true)
    }
    // useEffect(() => {
    //     axios.get(`${ URL.paramitha_v4 }HealthCareService / ${ practitionerRoleInfo?.practitionerRole?.healthcareService?.[0]?.reference?.split('/').pop() }`, {
    //         headers: {
    //             'X-FHIR-TENANT-ID': 'parimitha' || 'parimitha',
    //             'X-FHIR-DSID': `fhir${ selectBranchInfo?.tenant || 'parimitha'}`,
    //             'Realm': 'parimitha' || 'parimitha',
    //         }
    //     }).then((res) => {
    //         console.log(res, "987654321")
    //         // setUserData(res?.data)
    //         // setPractitionerRoleInfo(res?.data)

    //     }).catch((err) => console.log(err))
    // }, [])

    // useEffect(() => {
    //     axios.get(`${ URL.paramitha_v4 } PractitionerRole ? practitioner = ${ val?.resource?.id }& organization=`, {
    //         headers: {
    //             'X-FHIR-TENANT-ID': 'parimitha' || 'parimitha',
    //             'X-FHIR-DSID': `fhir${ selectBranchInfo?.tenant || 'parimitha' } `
    //         }
    //     }).then((res) => {
    //         console.log(res)
    //         // setUserData(res?.data)
    //         setPractitionerRoleInfo(res?.data?.entry[0])

    //     }).catch((err) => console.log(err))
    // }, [])

    // console.log(practitionerRoleInfo, "role")


    return (

        <Box>
            <Paper elevation={3} sx={{ p: "15px 10px" }} >
                <Grid item container >
                    <Grid item xs={12} sm={9} md={9} lg={9.5} xl={10} spacing={2}>
                        {/* <Grid item> */}
                        <div className="flex gap-4">
                            <CardMedia
                                sx={{
                                    height: 90, width: 90, border: '3px solid #fff', padding: "3px 3px 0px 3px", borderRadius: '12px', background: '#C6C6C6', objectFit: 'fill'
                                }}
                                className="shadow-xl"
                                component="img"
                                alt="gdoctor img"
                                width={130}
                                height="140"
                                image={practitionerData?.photo?.[0]?.url ? practitionerData?.photo?.[0]?.url : practitionerData?.gender === 'female' ? FemaleDoctorImg : MaleDoctorImg} />

                            {/* </Grid> */}
                            {/* <Grid item> */}
                            <Stack direction={"column"} spacing={0.2}>
                                <Typography sx={{ color: "#000000", fontSize: "18px", textTransform: 'capitalize', fontWeight: 500, cursor: 'pointer' }}
                                    onClick={handleViewProfile} >
                                    {/* {val?.resource?.name?.[0]?.text} */}
                                    {practitionerData?.name?.[0]?.text}
                                </Typography >
                                <Typography sx={{ color: "#707070", fontSize: "11px", }} >
                                    {practitionerData?.name?.[0]?.suffix?.length > 0 ? (
                                        practitionerData?.name?.[0]?.suffix?.map((item, index) => (
                                            <span key={index}>
                                                {item}{index < practitionerData?.name[0]?.suffix?.length - 1 ? ', ' : ''}
                                            </span>
                                        ))
                                    ) : null}
                                </Typography>
                                <Typography sx={{ color: "#000000", textTransform: 'capitalize', fontSize: '14px' }}  >
                                    {/* {val.experience} */}
                                    {/* {val?.resource?.identifier?.map((option, index) =>
                                        <div key={index}>{option.value}</div>
                                    )} */}

                                    <div >{val?.resource?.identifier?.[0]?.value}</div>

                                    {/* Senior Consultant Neonatology & pediatrices */}
                                </Typography>
                                <Typography sx={{ color: "#816565", fontSize: "14px", display: "flex", textTransform: 'capitalize', alignItems: "center", gap: "2px", }} >
                                    <span>
                                        {val?.resource?.specialty?.[0]?.coding?.[0]?.display ?
                                            <img
                                                src={StethIcon}
                                                width={20}
                                                height={20}
                                                alt="stethoscope"
                                            /> : null}
                                    </span>
                                    {val?.resource?.specialty?.[0]?.coding?.[0]?.display
                                        //  || 'General pediatrices ,Neonatology'
                                    }
                                    {/* {val.study} */}
                                </Typography>
                            </Stack >
                        </div >


                        {/* </Grid> */}
                    </Grid >
                    <Grid item sm={3} md={3} lg={2.5} xl={2} >

                        <Button variant="outlined" endIcon={<LaunchIcon />}
                            style={{ backgroundColor: "#205072", color: "#FFFFFF", textTransform: "capitalize", borderRadius: "10px", }}
                            sx={{
                                width: { xs: "130px", sm: "130px", md: "100%", lg: "160px", },
                                height: "30px",
                                fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px", },
                                justifyContent: 'space-between',
                            }}
                            onClick={handleViewProfile}
                        >
                            View Profile
                        </Button>
                    </Grid>
                </Grid >
                <div className="text-xs pt-3 font-semibold gap-2 flex mt-1 ">
                    <img src={CommunicationImg} /> Communicate in
                    {practitionerData?.communication?.map((lang) =>
                        <span key={lang?.coding?.[0]?.display} style={{ color: "#051984", fontWeight: 500 }}>
                            {lang?.coding?.[0]?.display}
                        </span>
                    )}

                </div>

                <Grid container className="text-base font-medium flex items-center gap-1 pt-2 pb-1"><PiFirstAidKit style={{ fontSize: '18px', fontWeight: 700, color: '#006072' }} /> Healthcare Services</Grid>
                <Grid container justifyContent={'space-between'}>
                    <Grid item xs={12} sm={9} md={9} lg={9.5} xl={10} spacing={2}>
                        {val?.resource?.HealthcareService ? (
                            ServicesTab?.slice(0, 3)?.map((item, index) => (
                                <Grid item key={index} xs={4} className="text-sm" sx={{ color: "#707070" }}>
                                    {item?.title}
                                </Grid>
                            ))
                        ) : (
                            <Grid item className="text-sm" sx={{ color: "#707070" }}>
                                {val?.resource?.HealthcareService}
                            </Grid>

                        )}

                    </Grid>
                    <Grid item sm={3} md={3} lg={2.5} xl={2} sx={{
                        width: { xs: "130px", sm: "130px", md: "160px", lg: "160px", },
                    }}>
                        <Button
                            variant="outlined"
                            startIcon={<img src={teleIcon} width={20} height={20} alt="tele" />}
                            sx={{
                                width: { xs: "130px", sm: "130px", md: "100%", lg: "160px", },
                                height: "30px",
                                fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px", },
                                justifyContent: 'space-between',
                                // padding:'5px',
                                textTransform: "capitalize",
                                borderRadius: "10px",
                                borderColor: "#205072",
                                color: "#205072",
                                "&:hover": { borderColor: "#205072" },
                            }}
                            onClick={handleConsultOnline}
                        >
                            Consult Online
                        </Button>
                    </Grid>

                </Grid>

                <Divider style={{ margin: "10px 0px", }} />
                <Grid container alignItems={'center'}>
                    <Grid item xs={12} sm={9} md={9} lg={9.5} xl={10} spacing={2}>

                        <Typography sx={{ color: "#000000" }} className="text-[16px] font-medium flex gap-1 items-center" >
                            <LocationOnOutlined sx={{ fontSize: { md: 16, lg: "18px" }, color: "#0F21B1", mr: "5px" }} />
                            Available at
                            <span className="text-sm" style={{ color: "#0F4BBE" }} > {selectBranchInfo?.name || 'Paramitha Hospitals'} </span>
                        </Typography>
                        {/* <div className="flex justify-between"> */}

                        {/* <Stack direction={"column"} spacing={1}> */}
                        {val?.resource?.availableTime ?
                            <div className="flex gap-2" >
                                <div className="font-medium pt-2 flex  gap-1">
                                    <EventAvailable sx={{ fontSize: "18px", color: "#4B4C52", mr: "5px", mt: '3px' }} />
                                    <div className='text-[16px]'>Available</div>
                                </div>
                                <div>
                                    <AvailableDays timingInfo={val?.resource?.availableTime} />
                                </div>
                            </div>
                            :
                            <div className="flex gap-1" >
                                <EventAvailable sx={{ fontSize: "18px", color: "#4B4C52", mt: '3px', mr: "5px" }} />
                                <div className='text-[16px]'>Available</div>
                                <Typography sx={{ fontSize: "14px", color: "#0F4BBE", mt: '3px' }}>No Schedule Slots Available</Typography>
                            </div>
                        }

                    </Grid>
                    {/* </Stack> */}
                    {/* <Grid > */}
                    <Grid item sm={3} md={3} lg={2.5} xl={2}>

                        < Button variant="outlined" startIcon={<img src={RequestIcon} width={20} height={20} alt="schedule" />}
                            style={{
                                backgroundColor: "#EAF5FD", color: "#205072", textTransform: "capitalize",
                                borderRadius: "10px",
                            }}
                            sx={{
                                width: { xs: "130px", sm: "130px", md: "100%", lg: "160px", },
                                height: "30px",
                                fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px", },
                                justifyContent: 'space-between',
                            }}

                            onClick={handleScheduleVisit}
                        // onClick={() => setOpen(true)}
                        >
                            Schedule Visit
                        </Button>
                    </Grid>
                </Grid>
                {/* </Grid> */}

                {/* </div> */}
            </Paper >
            <AppoinmentPopup open={open} setOpen={setOpen} selectedbranch={selectBranchInfo} userData={practitionerData} userRole={val} />
        </Box >

    );
};

export default DoctorProfileCard;
