import React, { useState } from 'react'
import { Box, Typography, Divider, Grid } from '@mui/material';
import { Edit, LocationOn } from '@mui/icons-material';
import '../healthportal.css';
import EditIcon from '../../../assets/healthportal-icons/edit.svg'
import moment from 'moment';
import InformationUpdate from './InformationUpdate';
import ContactsUpdate from './ContactsUpdate';
import AddressUpdate from './AddressUpdate';
import RelatedPerson from './RelatedPerson';

function Myprofile({ patientDetails, selectedbranch }) {
    const [editInfo, setEditInfo] = useState(false)
    const [editContact, setEditContact] = useState(false)
    const [editAddress, setEditAddress] = useState(false)
    const [editRelPerson, setEditRelPerson] = useState(false)
    return (
        <div>
            <Box sx={{ mt: 2 }} className="flex justify-between ">
                <Typography className='fntwt-500 textcol'>Profile Identifiers</Typography>
                <img src={EditIcon} alt="edit" className="cursor-pointer" />
            </Box>
            <Divider className='textbgcol' />
            <Box sx={{ mt: 2 }}>
                <Grid container>
                    <Grid item xs={12} md={4} sm={4}>
                        <Typography variant='body2'>Medical Records Number</Typography>
                        {/* <Typography className='fntwt-600'>PHKOMP9088909</Typography> */}
                        <Typography className='fntwt-600'>--</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                        <Typography variant='body2'>ABHA Address</Typography>
                        <Typography className='fntwt-500 fntsz-14' color={'#0D00AD'} >--</Typography>
                        {/* <Typography className='fntwt-500 fntsz-14' color={'#0D00AD'} >akhil9999@sbx</Typography> */}
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                        <Typography variant='body2'>Health Account Number</Typography>
                        <Typography className='fntwt-500 fntsz-14'>--</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ mt: 2 }} className="flex justify-between ">
                <Typography className='fntwt-500 textcol'>Personal Information</Typography>
                <img src={EditIcon} alt="edit" className="cursor-pointer" onClick={() => setEditInfo(true)} />
            </Box>
            <Divider sx={{ bgcolor: '#155775' }} />
            <Box sx={{ mt: 2 }}>
                <Grid container>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography variant='body2'>First Name</Typography>
                        <Typography className='fntwt-500 fntsz-14 capitalize'>
                            {`${patientDetails?.name?.[0]?.given?.[0]}`.slice(0, 12)}
                            {`${patientDetails?.name?.[0]?.given?.[0]}`.length > 12 ? '...' : ''}
                        </Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        {/* <Typography variant='body2'>Middle Name</Typography>
                        <Typography fontSize="14px">--</Typography> */}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography variant='body2'>Last Name</Typography>
                        <Typography className="fntwt-500 fntsz-14 capitalize">
                            {`${patientDetails?.name?.[0]?.family || '---'}`.slice(0, 12)}
                            {patientDetails?.name?.[0]?.family?.length > 12 ? '...' : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container mt={1}>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography variant='body2'>Gender</Typography>
                        <Typography className='fntwt-500 fntsz-14 capitalize'>{patientDetails?.gender}</Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography variant='body2'>Date of Birth</Typography>
                        <Typography className='fntwt-500 fntsz-14'>{moment(patientDetails?.birthDate).format('Do MMMM YYYY')}</Typography>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography variant='body2'>Communication Languages</Typography>
                        <Typography className='fntwt-500 fntsz-14' >
                            {
                                patientDetails?.communication?.length > 0 ? (
                                    patientDetails.communication.map((lang, index) => (
                                        <span key={index}>{lang?.language?.coding?.[0]?.display},</span>
                                    ))
                                ) : (
                                    <span>---</span>
                                )
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ mt: 2 }} className="flex justify-between ">
                <Typography className='fntwt-500 textcol'>Contact Details</Typography>
                <img src={EditIcon} alt="edit" className="cursor-pointer" onClick={() => setEditContact(true)} />
            </Box>
            <Divider sx={{ bgcolor: '#155775' }} />
            <Box sx={{ mt: 2 }}>
                <Grid container>
                    <Grid item md={6} xs={12} sm={6}>
                        <Typography fontWeight={600}>Phone</Typography>
                        <Typography variant='body2'>Mobile :<Typography component={'span'} className='fntwt-500 fntsz-14'> {patientDetails?.telecom?.[0]?.value || '---'}</Typography> </Typography>
                        {/* <Typography variant='body2'>Home <Typography component={'span'} className='fntwt-500 fntsz-14'>--</Typography> </Typography> */}
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <Typography fontWeight={600}>Email</Typography>
                        <Typography variant='body2'>Personal : <Typography component={'span'} className='fntwt-500 fntsz-14'>{patientDetails?.telecom?.[1]?.value || '---'}</Typography> </Typography>
                        {/* <Typography variant='body2'>Work    <Typography component={'span'} className='fntwt-500 fntsz-14'>--</Typography> </Typography> */}
                    </Grid>

                </Grid>

            </Box >
            <Box sx={{ mt: 2 }} className="flex justify-between ">
                <Typography className='fntwt-500 textcol'>Address</Typography>
                <img src={EditIcon} alt="edit" className="cursor-pointer" onClick={() => setEditAddress(true)} />
            </Box>
            <Divider sx={{ bgcolor: '#155775' }} />
            <Box sx={{ mt: 2 }}>
                <Grid container>

                    <Grid item>
                        <Typography variant='body2'>Home Address</Typography>
                        {/* <Typography component={'p'} className='fntwt-500 fntsz-14' >1-4-210/8, Sadana Vihar Colony, Kapra,Medchal Malkajgiri Jawaharnagar (CT),Telangana,India - 500062</Typography> */}
                        <Typography component={'p'} className='fntwt-500 fntsz-14 capitalize' >
                            {`${patientDetails?.address?.[0]?.line[0] ? patientDetails?.address?.[0]?.line[0] + ',' : '-'} ${patientDetails?.address?.[0]?.line[1] ? patientDetails?.address?.[0]?.line[1] + ',' : '-'}${patientDetails?.address?.[0]?.city ? patientDetails?.address?.[0]?.city + ',' : '-'}${patientDetails?.address?.[0]?.state ? patientDetails?.address?.[0]?.state + ',' : '-'}${patientDetails?.address?.[0]?.district ? patientDetails?.address?.[0]?.state + ',' : '-'}${patientDetails?.address?.[0]?.postalCode ? patientDetails?.address?.[0]?.postalCode : '-'}`}

                        </Typography>
                    </Grid>
                </Grid>

            </Box>
            {/* <Box sx={{ mt: 2 }} className="flex justify-between ">
                <Typography className='fntwt-500 textcol'>Related Person</Typography>
                <img src={EditIcon} alt="edit" className="cursor-pointer" onClick={() => setEditRelPerson(true)} />
            </Box>
            <Divider sx={{ bgcolor: '#155775' }} /> */}
            <Box sx={{ mt: 2 }}>
                {/* <Grid container>
                    <Grid item>
                        <Typography sx={{ fontSize: 17, fontWeight: 600 }}>Emergency Contact </Typography>
                        <Typography fontWeight={500}>{patientDetails?.contact?.[0]?.name?.given[0] || ''} {patientDetails?.contact?.[0]?.name?.family || ''}{patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.display && `(${patientDetails.contact[0].relationship[0].coding[1].display})`}
                        </Typography>
                        <Typography fontSize='13px' mt='2px' bgcolor="#F1FAFF"><LocationOn sx={{ fontSize: 13 }} /> Paramita Children's Hospital,{selectedbranch?.name}</Typography>
                    </Grid>
                </Grid>
                <Grid container mt={2} >
                    <Grid item md={6} xs={12} sm={6}>
                        <Typography variant='body2'> Contact </Typography>
                        <Typography className='fntwt-500 fntsz-14'>Phone : {patientDetails?.contact?.[0]?.telecom?.[0].value || ''} </Typography>
                        <Typography className='fntwt-500 fntsz-14'>Email : {patientDetails?.contact?.[0]?.telecom?.[1].value || ''}</Typography>
                    </Grid>
                    <Grid item md={6} xs={12} sm={6}>
                        <Typography variant='body2'>Address </Typography>
                        <Typography component={'p'} className='fntwt-500 fntsz-14'>
                            {`${patientDetails?.contact?.[0]?.address?.line[0] || ''}${patientDetails?.contact?.[0]?.address?.line[1] ? ', ' + patientDetails?.contact?.[0]?.address?.line[1] : ''}${patientDetails?.contact?.[0]?.address?.city ? ', ' + patientDetails?.contact?.[0]?.address?.city : ''}`}
                        </Typography>
                        <Typography component={'p'} >
                            {`${patientDetails?.contact?.[0]?.address?.district ? patientDetails.contact[0].address.district + ',' : ''} ${patientDetails?.contact?.[0]?.address?.state ? patientDetails.contact[0].address.state + ',' : ''} ${patientDetails?.contact?.[0]?.address?.postalCode || ''}`}
                        </Typography>
                    </Grid>
                </Grid> */}
                <InformationUpdate open={editInfo} setEditInfo={setEditInfo} selectedbranch={selectedbranch} patientDetails={patientDetails} />
                <ContactsUpdate open={editContact} setEditContact={setEditContact} selectedbranch={selectedbranch} patientDetails={patientDetails} />
                <AddressUpdate open={editAddress} setEditAddress={setEditAddress} selectedbranch={selectedbranch} patientDetails={patientDetails} />
                <RelatedPerson open={editRelPerson} setEditRelPerson={setEditRelPerson} selectedbranch={selectedbranch} patientDetails={patientDetails} />
            </Box>
        </div >
    )
}

export default Myprofile;