import React, { useEffect, useState } from 'react'
import { calculateAge } from '../atoms/calculateAge';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import { ArrowCircleRightOutlined } from '@mui/icons-material';
import BranchUrl from '../../services/api_branch_url';
import { localstore } from '../localstore/localstore';
import axios from 'axios';
import StringAvatar from '../atoms/stringAvatar';

function AllPatientProfiles({ person, handleSwitchProfile }) {
    const [patient, setPatient] = useState('');
    const patientIbmId = person?.target?.reference;
    const urls = BranchUrl();
    const usertoken = localstore.getToken();

    useEffect(() => {
        if (patientIbmId && usertoken) {
            axios.get(`${urls.person_tenant_orgId}${patientIbmId}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'Realm': 'parimitha',
                    'Authorization': `Bearer ${usertoken}`
                }
            }).then(res => {
                setPatient(res.data);
            }).catch(err => {
                console.error(err);
            });
        }
    }, [patientIbmId, usertoken, urls.person_tenant_orgId]);




    return (
        <Box >
            <div className='cursor-pointer' onClick={() => handleSwitchProfile(patient?.id, patient)}>
                <Grid container m={"10px 0px"} alignItems={'center'}>
                    <Grid item md={10.8} className="flex items-center" gap={"12px"}>
                        {/* <Avatar {...stringAvatar(`${personData?.resource?.name?.[0]?.given} ${personData?.name?.[0]?.family}`)} /> */}
                        {/* <Avatar variant="rounded" {...StringAvatar(`${`${patient?.name?.[0]?.text?.toUpperCase()} ${patient?.name?.[0]?.family?.toUpperCase() ?? ''}`.slice(0, 12)}${`${patient?.name?.[0]?.text?.toUpperCase()} ${patient?.name?.[0]?.family?.toUpperCase() ?? ''}`.length > 12 ? '...' : ''}`)} /> */}
                        <Avatar
                            variant="rounded"
                            {...StringAvatar(
                                patient?.name?.[0]?.text
                                    ? `${patient.name[0].text.toUpperCase()}`.slice(0, 12) +
                                    `${patient.name[0].text.length > 12 ? '...' : ''}`
                                    : 'N/A' // Default text if no name is available
                            )}
                        />
                        <div>
                            <Typography fontSize={{ xs: '12px', sm: '14px', md: 14, lg: 14 }} fontWeight={500} textTransform={'capitalize'}>
                                {patient?.name?.[0]?.given} {patient?.name?.[0]?.family}
                            </Typography>

                            <Typography fontSize={{ xs: '11px', sm: 13, md: 13, lg: 13 }}>
                                {patient?.birthDate ? `${calculateAge(patient?.birthDate)} Y` : ''}/{patient?.gender && patient?.gender.charAt(0).toUpperCase()}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item md={1.2}>
                        {/* <Logout /> */}
                        <ArrowCircleRightOutlined sx={{ color: '#205072' }} />
                    </Grid>
                </Grid>
                {patient.length > 1 && <Divider />}
            </div>
        </Box>

    );
}

export default AllPatientProfiles