import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Autocomplete, Paper, Button, CardMedia } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { EventAvailable, GTranslateOutlined, LocationOnOutlined } from "@mui/icons-material";
import MaleDoctorImg from '../../assets/images/MaleDoctor-no-photo.svg';
import FemaleDoctorImg from '../../assets/images/FemaleDoctor-No Photo.svg';
import StethIcon from '../../assets/images/stethoscope.svg';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import { useSelector } from 'react-redux';
import BranchUrl from '../../services/api_branch_url';
import AppoinmentPopup from '../doctors/appointmentPopup';
import moment from 'moment';
import { createInteractionData } from '../atoms/getLocations';
import { sendInteractionData } from '../doctors/webSocketManager';

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const daysCompare = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };

export const PractitionerCard = ({ val, practionerInfo }) => {


    const [practitionerData, setPractitionerData] = useState({})
    const [userData, setUserData] = useState({})
    // console.log(userData, "userData")
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const branchName = useSelector((state) => state.branch.branch)
    const [open, setOpen] = useState(false)
    const urls = BranchUrl()
    const pathId = window.location.pathname
    const navigate = useNavigate()
    useEffect(() => {
        const practId = val
        if (practId) {
            axios.get(`${urls.fhir_tenant_orgId}${val?.resource?.practitioner?.reference}`, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'Realm': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`
                }
            }).then((res) => {
                // console.log(res, "hllo12345678")
                // setUserData(res?.data)
                // setPractitionerRoleInfo(res?.data)
                setPractitionerData(res?.data)

            }).catch((err) => console.log(err))
        }

        // if (practionerInfo) {
        //     console.log(practionerInfo, 41)
        //     setPractitionerRoleInfo(practionerInfo?.practitionerRole?.resource)
        //     setPractitionerData(practionerInfo?.practitioner?.resource)
        // }
    }, [])
    // console.log("practitionerData", practitionerData)

    const [WorkingDays, setWorkingDays] = useState([]);
    const [timings, setTimings] = useState({});
    const [activeDay, setActiveDay] = useState(null);
    // console.log(timingInfo, "timing")
    // console.log(activeDay, "activeday")
    const timingInfo = val?.resource?.availableTime
    useEffect(() => {
        if (timingInfo) {
            let data = [];
            const date = new Date();
            const today = days[date.getDay() - 1];
            // console.log(today, "today")
            {
                timingInfo?.map((item, i) => {
                    let openingTime = item.availableStartTime;
                    let closingTime = item.availableEndTime;
                    if (item.allDay == true) {
                        item?.daysOfWeek?.map((child) => {
                            data.push({
                                day: child,
                                allDay: item.allDay,
                                today: child == today ? true : false,
                            });
                        });
                    } else if (item.allDay == false) {
                        item.daysOfWeek?.map((child) => {
                            data.push({
                                day: child,
                                allDay: item.allDay,
                                today: child == today ? true : false,
                            });
                        });
                    } else {
                        item.daysOfWeek.map((child) => {
                            data.push({
                                day: child,
                                workingHours: [
                                    { openingTime: openingTime, closingTime: closingTime },
                                ],
                                today: child == today ? true : false,
                            });
                        });
                    }
                });
            }


            const mergedSchedule = [];

            for (let i = 0; i < data.length; i++) {
                let day = data[i].day;
                let hours = data[i].workingHours;

                let existingDay = mergedSchedule.find((item) => item.day === day);
                // console.log(existingDay, "existingDay")
                if (existingDay) {
                    existingDay.workingHours = existingDay.workingHours.concat(hours);
                    // console.log(existingDay, "existingDay11")
                } else {
                    mergedSchedule.push(data[i]);
                }
            }
            // console.log(mergedSchedule, "mergedSchedule,258");

            const sorted = mergedSchedule.sort((a, b) => {
                return daysCompare[a.day] - daysCompare[b.day];
            });
            // console.log(sorted, "sorted");
            setWorkingDays(sorted);
        }
    }, [timingInfo])
    useEffect(() => {
        const date = new Date();
        const today = days[date.getDay() - 1];
        WorkingDays?.find((item) => {
            if (item.day == today) {
                // console.log(item)
                setTimings(item);
            }
        });
    }, [WorkingDays]);

    const getIdentifierValue = (system) => {
        // Find the identifier object where system matches the given system
        const identifier = practitionerData?.identifier?.find(id => id.system === system);

        // If found, return the value, otherwise return null or empty string
        return identifier ? identifier?.value : null;
    };
    const paramithaIdentifierValue = getIdentifierValue("https://paramithahospitals.com");

    const handleViewProfile = async () => {
        const pageTitle = paramithaIdentifierValue
        let activity = "view profile"
        const interactionData = await createInteractionData(pathId, pageTitle, activity);
        sendInteractionData(interactionData);
        navigate(branchName ? `/${branchName}/doctor/${paramithaIdentifierValue}` : `/doctor/${paramithaIdentifierValue}`)
    }

    const handleScheduleVisit = async () => {
        const pageTitle = paramithaIdentifierValue
        const activity = "schedule visit"
        const interactionData = await createInteractionData(pathId, pageTitle, activity);
        sendInteractionData(interactionData);
        setOpen(true)
    }

    const customStyles = {
        fontSize: '10px'
    };
    const customTime = {
        fontSize: '9px'
    };

    return (

        <Box>
            <Paper
                elevation={3}
                sx={{
                    cursor: "pointer",
                    minHeight: 380,
                    mt: 1,
                    p: "20px 10px",
                    position: 'relative'
                }}
            >
                <div className='flex gap-3'>
                    {/* <img style={{
                        border: '3px solid #fff',
                        padding: "3px",
                        borderRadius: '12px',
                        background: '#C6C6C6',
                        objectFit: 'fill', width: 80, height: 80
                    }} src={practitionerData?.photo?.[0]?.url ? practitionerData?.photo?.[0]?.url : practitionerData?.gender === 'female' ? FemaleDoctorImg : MaleDoctorImg} alt="" /> */}
                    <CardMedia
                        sx={{
                            height: { xs: 70, md: 80 },
                            width: { xs: 70, md: 80 },
                            border: '3px solid #fff',
                            padding: "3px",
                            borderRadius: '12px',
                            background: '#C6C6C6',
                            objectFit: 'fill'
                        }}
                        className="shadow-md"
                        component="img"
                        alt="doctor img"
                        image={practitionerData?.photo?.[0]?.url ? practitionerData?.photo?.[0]?.url : practitionerData?.gender === 'female' ? FemaleDoctorImg : MaleDoctorImg} />
                    <Box>
                        <Typography
                            sx={{
                                color: "#000000",
                                fontSize: { xs: 18, md: "23px" },
                                fontWeight: "500",
                                cursor: 'pointer',
                                textTransform: 'capitalize'
                            }}
                            onClick={handleViewProfile}
                        >
                            {practitionerData?.name?.[0]?.text}
                        </Typography>
                        <Typography sx={{ color: "#707070", fontSize: "13px" }}>
                            {practitionerData?.name?.[0]?.suffix?.length > 0 ? (
                                practitionerData?.name?.[0]?.suffix?.map((item, index) => (
                                    <span key={index}>
                                        {item}{index < practitionerData?.name[0]?.suffix?.length - 1 ? ', ' : ''}
                                    </span>
                                ))
                            ) : null}
                        </Typography>
                        <Typography sx={{ textTransform: 'capitalize' }}>
                            {/* {val?.resource?.identifier?.length > 0 ?
                            val?.resource?.identifier?.map((option, index) =>
                                <div key={index} style={{ color: "#000000", minHeight: 25 }} className='md:text-lg'>{option.value}</div>
                            ) : <div style={{ color: "#000000", minHeight: 25, fontSize: '18px' }}></div>} */}

                            {val?.resource?.identifier ?
                                <div style={{ color: "#000000", }} >{val?.resource?.identifier?.[0]?.value}</div> :
                                <div style={{ color: "#000000", fontSize: '18px' }}></div>
                            }
                        </Typography>
                        <Typography
                            sx={{
                                color: "#525C79",
                                fontSize: { xs: "12px", md: "16px" },
                                display: "flex",
                                height: '29px',
                                alignItems: "center",
                                gap: "2px",
                                fontWeight: 'normal',
                                textTransform: 'capitalize',
                                // mt: "2px"
                            }}
                        >
                            <span>
                                {val?.resource?.specialty?.[0]?.coding?.[0]?.display ?
                                    <img
                                        src={StethIcon}
                                        width={20}
                                        height={20}
                                        alt="stethoscope"
                                        sx={{ fontSize: "12px", mr: "5px" }}
                                    /> : <div> </div>}
                            </span>
                            {val?.resource?.specialty?.[0]?.coding?.[0]?.display}
                        </Typography>
                    </Box>
                </div>
                <div>
                    <Typography
                        sx={{
                            color: "#000000",
                            fontSize: "16px",
                            fontWeight: "500",
                            mt: '5px',
                            display: 'flex', alignItems: 'center', gap: "5px"
                        }}
                    >
                        <LocationOnOutlined sx={{ fontSize: "16px", color: '#E54290', mr: "3px" }} />
                        <span
                            style={{
                                color: "#0F4BBE",
                                fontSize: "16px"
                            }}
                        >
                            {selectBranchInfo?.name || 'Paramitha Hospitals'}
                        </span>
                    </Typography>
                    <Typography sx={{ color: "#000000", fontSize: "16px", mt: '4px', display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {practitionerData?.communication?.length > 0 && (
                            <>
                                <GTranslateOutlined sx={{ fontSize: "16px", mr: "5px", color: '#E54290' }} />
                                {practitionerData?.communication?.map((lang, index) => (
                                    <span key={index} style={{ color: "#040321", fontWeight: 500 }}>
                                        {lang?.coding?.[0]?.display ? `${lang?.coding?.[0]?.display}${index < practitionerData.communication.length - 1 ? ', ' : ''}` : ''}
                                    </span>
                                ))}
                            </>
                        )}
                    </Typography>
                    <div style={{ display: "flex", gap: "5px", margin: "12px 0px", flexWrap: 'wrap' }}>
                        {WorkingDays?.map((item, i) => {
                            return (
                                <Button
                                    key={i}
                                    variant="outlined"
                                    onClick={() => {
                                        setTimings(item);
                                        setActiveDay(i);
                                    }}
                                    sx={{
                                        minWidth: "50px",
                                        minHeight: '30px',
                                        border: '1px solid #E54290',
                                        background:
                                            activeDay == i && activeDay !== null
                                                ? "#480223"
                                                : item.today && activeDay == null
                                                    ? "#480223"
                                                    : !item.allDay && !item.hasOwnProperty("workingHours")
                                                        ? "#fff"
                                                        : "#fff",
                                        padding: "0px 7px",
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            color: activeDay == i && activeDay !== null
                                                ? "#fff"
                                                : item.today && activeDay == null
                                                    ? "#fff"
                                                    : !item.allDay && !item.hasOwnProperty("workingHours")
                                                        ? "#E54290"
                                                        : "#E54290",
                                            textTransform: "capitalize",
                                        }}
                                        style={customStyles}
                                    >
                                        {item.day}
                                    </Typography>
                                </Button>
                            );
                        })}
                    </div>
                    {val?.resource?.availableTime ?
                        <Box
                            sx={{
                                color: "#000000",
                                fontSize: "16px",
                                fontWeight: "500",
                                mt: '-5px',
                                display: 'flex', gap: '15px', alignItems: 'center'
                            }}
                        >
                            <div className='flex pt-3 gap-1'>
                                <EventAvailable
                                    sx={{ fontSize: "18px", color: '#E54290', mr: "5px", mt: '3px' }}
                                />
                                <div className='text-[16px]'>Available -</div>
                            </div>
                            <div className='pt-2'>
                                {timings && timings.allDay ? (
                                    <Typography variant="body2" className="timing" style={customStyles}>
                                        All Day
                                    </Typography>
                                ) : timings.allDay == false ? (
                                    <Typography variant="body2" className="timing" style={customStyles} >
                                        No Slot available
                                    </Typography>
                                ) :
                                    (
                                        timings?.workingHours ?
                                            timings?.workingHours?.map((item, ind) => {
                                                // console.log(item, "");
                                                return (
                                                    <Typography
                                                        key={ind}
                                                        variant="body2"
                                                        className="timing"
                                                        style={customStyles}

                                                    >
                                                        {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                                                        &nbsp;&nbsp;-&nbsp;&nbsp;
                                                        {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
                                                    </Typography>
                                                );
                                            }) : <Typography variant="body2" className="timing" style={customStyles}>
                                                Today No Slots available
                                            </Typography>
                                    )
                                }
                            </div>
                        </Box> :
                        <Typography
                            sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: "500",
                                mt: '10px',
                                display: 'flex', gap: '10px'
                            }}
                        >
                            <EventAvailable sx={{ fontSize: "18px", color: '#E54290', mt: '2px' }} />
                            <div className='text-[16px]'>Available</div>
                            <Typography sx={{ height: 52, fontSize: "16px", color: "#0F4BBE" }}>No Schedule Slots Available</Typography>
                        </Typography>
                    }

                    <Box sx={{ display: "flex", justifyContent: "space-around", mt: "12px", p: "0px 0px 10px 0px", position: { md: 'absolute' }, bottom: 0, left: 0, right: 0 }} >
                        {/* <Button
                                variant="outlined"
                                startIcon={<img src={teleIcon} width={20} height={20} alt="tele" />}
                                sx={{
                                    width: { xs: "130px", sm: "130px", md: "140px", lg: "150px" },
                                    height: "30px",
                                    fontSize: { xs: "10px", sm: "10px", md: "10px", lg: "12px" },
                                    textTransform: "capitalize",
                                    borderRadius: "10px",
                                    borderColor: '#205072',
                                    color: '#205072',
                                    "&:hover": { borderColor: "#205072" }
                                }}
                            >
                                Consult Online
                            </Button> */}
                        <Box sx={{ display: "flex", alignItems: "center", gap: '2px' }} onClick={() => navigate(branchName ? `/${branchName}/doctor/${practitionerData?.id}` : `/doctor/${practitionerData?.id}`)}>
                            <Typography sx={{ color: "#E54290", fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px", }, }}>
                                Visit Profile
                            </Typography>

                        </Box>
                        <Button
                            variant="outlined"
                            startIcon={<EventAvailableIcon />}
                            style={{ backgroundColor: "#E54290", color: "#FFFFFF", textTransform: "capitalize", borderRadius: "10px" }}
                            sx={{
                                // width: { xs: "130px", sm: "130px", md: "140px", lg: "150px" },
                                height: "45px",
                                fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "18px", },
                            }}
                            onClick={handleScheduleVisit}                            >
                            Book Appointment
                        </Button>
                    </Box>
                </div>
            </Paper>
            <AppoinmentPopup open={open} setOpen={setOpen} selectedbranch={selectBranchInfo} userRole={val} userData={practitionerData} />

        </Box >
    )
}
