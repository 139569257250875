import React, { useState, useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './mediaGallery.css';
import { getMediaGallery } from "../../redux/actions/actions";
import { ReactPhotoCollage } from "react-photo-collage";

function MediaGallery({ locationInfo }) {
    const servicePageId = locationInfo?.resource?.id;
    const [open, setOpen] = useState(false);
    const [postedGalleryList, setPostedGalleryList] = useState([]);
    const videoRef = useRef("");

    // Fetch media gallery data using the servicePageId
    useEffect(() => {
        const fetchData = async () => {
            try {
                const mediaGallery = await getMediaGallery(servicePageId);
                if (mediaGallery) {
                    setPostedGalleryList(mediaGallery);
                } else {
                    setPostedGalleryList([]);
                }
            } catch (error) {
                console.error("Error fetching media gallery", error);
            }
        };

        if (servicePageId) {
            fetchData();
        }
    }, [servicePageId]);

    // Settings for the photo collage (used when there are multiple images)
    const collageSettings = {
        width: "100%",
        height: ["200px", "200px"],
        layout: [1, 3],  // Adjust layout as per number of images
        photos: postedGalleryList.map((img) => ({
            source: img.sourceLink
        })),  // Map over your images array
        showNumOfRemainingPhotos: true,
    };

    return (
        <div>
            <div className="font-semibold text-lg">Media Gallery</div>
            <div className="mt-1">
                {postedGalleryList.length > 0 ? (
                    <div>
                        {/* Render photo collage if there are images */}
                        <ReactPhotoCollage {...collageSettings} />
                    </div>
                ) : (
                    <p>No media available</p>  // Message when no media is available
                )}
            </div>
        </div>
    );
}

export default MediaGallery;
