import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import URL from '../../../services/api_url'
import axios from 'axios'
import { Avatar, Box, Button, CardMedia, CircularProgress, ClickAwayListener, DialogContent, DialogTitle, Divider, Grid, IconButton, MenuItem, MenuList, Paper, Switch, Typography } from '@mui/material'
import { timeSince } from '../../atoms/timeSince'
import moment from 'moment'
import ShareImg from "../../../assets/images/Share 1.svg";
import { Close, Public } from '@mui/icons-material'
import FeedArticle from '../feedPost/feedArticle'
import FeedEvent from '../feedPost/feedEvent'
import FeedText from '../feedPost/feedtext'
import FeedVideo from '../feedPost/feedVideo'
import LikedIcon from "../../../assets/images/liked-icon.svg";
import likeIcon from "../../../assets/images/like-icon.svg";
import { CommentTextField } from '../../atoms/commentTextField'
import { localstore } from '../../localstore/localstore'
import { useDispatch, useSelector } from 'react-redux'
import CommonDialog from '../../atoms/commonModal'
import ContainedButton from '../../atoms/commonbutton'
import { getMetaData } from '../../atoms/getLocations'
import BranchUrl from '../../../services/api_branch_url'
import { getpostbyid } from '../../../redux/actions/loginActions'
import ImageWithModal from './ModalImage'
import { SocialShare } from '../../atoms/socalShare'
import PostMedia from '../feedPost/feedMedia'
import PollPost from './Poll'
import FeedPoll from '../feedPost/feedPoll'



function FeedPost() {
  const { id } = useParams()
  const [item, setItem] = useState({})
  const [like, setLike] = useState(false);
  const [comment, setComment] = useState(false)
  const [askComment, setAskComment] = useState({})
  const [commentVal, setCommentVal] = useState('')
  const [patient, setPatient] = useState({})
  const [loading, setLoading] = useState(false)
  const [replyComments, setReplyComments] = useState([])
  const [openImage, setOpenImage] = useState(false)
  const usertoken = localstore.getToken()
  const patientName = localstore.getPatientName()
  const [personLikes, setPersonLikes] = useState([])
  const ibmId = localstore.getIbmId()
  const [expanded, setExpanded] = useState(false);
  const [anonymous, setAnonymous] = useState(false);

  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const patientIbmId = localstore.getPatientIbmId()
  const urls = BranchUrl()
  const navigate = useNavigate()
  const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
  const branchName = useSelector((state) => state.branch.branch)
  const personDetails = useSelector((state) => state.paramitha.patientDetails)
  const postdata = useSelector((state) => state.login.postdata)
  const [openShare, setOpenShare] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);


  // console.log(postdata, "postdata")
  // console.log(item, "items")
  // console.log(personDetails, "persondetails")
  // console.log(patient, "patienta")
  const dispatch = useDispatch()

  const handleShareClose = () => {
    setOpenShare((prev) => !prev)
    setIsShareOpen(false)
  }
  useEffect(() => {
    const targetReference = personDetails?.link?.[0]?.target?.reference;

    if (ibmId && usertoken && targetReference) {
      axios.get(`${urls.person_tenant_orgId}${targetReference}`, {
        headers: {
          'X-FHIR-TENANT-ID': 'parimitha',
          'X-FHIR-DSID': 'fhirparimitha',
          'Realm': 'parimitha',
          'Authorization': `Bearer ${usertoken}`,
        }
      }).then(res => {
        setPatient(res.data);
      }).catch(err => {
        console.error(err);
      });
    }
  }, [ibmId, usertoken, personDetails?.link?.[0]?.target?.reference]);


  const fetchLikePersons = async () => {
    try {
      const res = await axios.get(`${URL.publish}allLikesbypost/${id}`)
      console.log(res.data, "alllikes data")
      setPersonLikes(res?.data)
    } catch {

    }
  }
  useEffect(() => {
    fetchLikePersons()
  }, [item?.id])


  const fetchPost = async () => {
    if (id) {
      setLoading(true)
      if (ibmId && usertoken) {
        try {
          const res = await axios.get(`${URL.publish}getpostById/${id}`, {
            headers: {
              'Authorization': `Bearer ${usertoken}`
            }
          })
          setItem(res?.data)
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }
      }
      else {
        try {
          // const res = await axios.get(`${URL.feed}feed/postwithouttoken/${id}`)
          const res = await axios.get(`${URL.publish}getpostById/${id}`)

          setItem(res?.data)
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }
      }
    }

  }
  useEffect(() => {
    if (item?.id === postdata?.id) {
      setItem(postdata)
    }
  }, [item, postdata])

  useEffect(() => {
    fetchPost()
    if (usertoken) {
      dispatch(getpostbyid(id, usertoken))
    }
  }, [id, usertoken])

  const handleComment = () => {
    if (usertoken && ibmId) {
      setComment(true)
    } else {
      alert('Please log in to view the data!')
      navigate(branchName ? `/${branchName}/login` : '/login')
    }

  }


  const getComments = async () => {
    if (usertoken) {

      try {
        // const res = await axios.get(`${URL.publish}commentsbypost/${id}?status=true`, {
        const res = await axios.get(`${URL.publish}getCommentsbypostId/${id}?status=true`, {
          headers: {
            "Authorization": `Bearer ${usertoken}`
          }
        })
        setReplyComments(res.data || [])
      } catch (error) {
        console.log(error)
      }
    }
  }
  useEffect(() => {
    getComments()
  }, [id])


  const handleAddLike = async () => {
    if (ibmId && usertoken) {

      setLike(true);
      const metadata = await getMetaData();

      const data = {
        "userData": {
          fullName: patientName,
          profilePic: null,
          caption: null,
        },
        "date": new Date().toISOString(),
        "likedBy": localstore?.getUserId(),
        "metadata": metadata,
        "postId": id
      }
      await axios.post(`${URL.publish}addlikes`, data, {
        headers: {
          'Authorization': `Bearer ${usertoken}`
        }
      }).then(res => {
        dispatch(getpostbyid(item?.id, usertoken));
        fetchLikePersons()
      })
    } else {
      setOpenMessage(true)
      // alert('Your are unable to access the data! Please Login Or SignUp')
      // navigate(selectedbranch?.name ? `/${selectedbranch?.name}/login` : '/login')
    }
  }

  const handleUnlike = () => {
    setLike(false);
    axios
      .delete(`${URL.publish}deletelikesbyid/${item.likedId}`, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      })
      .then(res => {
        dispatch(getpostbyid(item?.id, usertoken));
        fetchLikePersons()
      })
      .catch(err => {
        console.log(err.message, 'handleUnlike');
      });
  }

  const addComment = async () => {
    const metadata = await getMetaData();

    if (ibmId && usertoken) {
      try {
        const data = {
          "anonymous": anonymous,
          comment: commentVal,
          commentBy: localstore?.getUserId(),
          date: new Date().toISOString(),
          metadata: metadata, // Ensure metadata is called properly
          userData: {
            fullName: patient?.name?.[0]?.text || '',
            profilePic: null,
            caption: null,
          },
          postId: id,
        };

        const res = await axios.post(`${URL.publish}addcomment`, data, {
          headers: {
            'Authorization': `Bearer ${usertoken}`,
          },
        });

        if (res.status === 200 || res.status === 201) {
          setAskComment(res.data || {})
          getComments()
          setCommentVal('')
          // getComments(); // Call getComments after the post is successful
        }
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    } else {
      setOpenMessage(true)
      // alert('Your are unable to access the data, please log in or sign up for an account')
      // navigate(selectedbranch?.name ? `/${selectedbranch?.name}/login` : '/login')
    }

  }

  const image = item?.sourceLink?.replace(/,\s*$/, "");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const getVideoHeight = () => {
    if (windowWidth < 480) {
      return '150px';
    } else if (windowWidth < 768) {
      return '200px';
    } else if (windowWidth < 1024) {
      return '300px';
    } else {
      return '420px';
    }
  };


  return (
    <Box sx={{ p: { xs: '0px 10px', sm: '0px 10px', md: '10px 40px', lg: '10px 90px' } }}>

      <Box>
        {loading ? (
          <CircularProgress sx={{ display: "block", mx: "auto", mt: 3 }} />
        ) : (
          <div className='p-4'>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  sx={{
                    // Adjust maxHeight based on the screen size, using 100vh minus a fixed value
                    maxHeight: {
                      xs: 'calc(100vh - 100px)',  // For mobile screens
                      sm: 'calc(100vh - 100px)',  // For small screens
                      md: 'calc(100vh - 120px)',  // For medium screens
                      lg: 'calc(100vh - 100px)',  // For large screens (adjust for taller screens)
                    },
                  }}
                >
                  {
                    item.postType === "TEXT" ? (
                      <FeedText docFeed={item} />) :
                      item.postType === "POLL" ? (
                        <FeedPoll docFeed={item} />
                      ) : item.postType === "IMAGE" ? (
                        <PostMedia item={item} />
                      ) : item.postType === "VIDEO" ? (
                        <video
                          controls
                          width="100%"
                          style={{
                            minHeight: getVideoHeight()
                            , maxWidth: '100%', objectFit: 'contain'
                          }}
                        >
                          <source src={image} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : item.postType === "ARTICLE" ? (
                        <div>
                          {item?.sourceLink ? (
                            <Box
                              sx={{ width: '100%' }}
                              onClick={() => setOpenImage(true)}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={image}
                                alt="post"
                                style={{
                                  objectFit: 'contain',  // Ensure the image maintains its aspect ratio
                                  width: '100%',
                                  maxHeight: '430px',  // Ensure max height
                                  borderRadius: '10px',
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                height: { md: '400px', lg: '430px' },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              No Image Available
                            </Box>
                          )}
                        </div>
                      ) : item.postType === "EVENT" ? (
                        <div>
                          {item?.eventData?.eventImage ? (
                            <Box
                              sx={{ width: '100%' }}
                              onClick={() => setOpenImage(true)}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={item?.eventData?.eventImage}
                                alt="post"
                                style={{
                                  objectFit: 'contain',  // Ensure image maintains aspect ratio
                                  width: '100%',
                                  maxHeight: '430px',  // Ensure max height for image
                                  borderRadius: '10px',
                                }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                height: { md: '300px', lg: '430px' },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              No Image Available
                            </Box>
                          )}
                        </div>
                      ) : null
                  }

                  <Box
                    sx={{
                      display: "flex",
                      pl: 1,
                      pr: 1,
                      mt: 1,
                      justifyContent: "space-between",
                      height: "22px",
                      px: "10px"
                    }}
                  >
                    {item?.likeCount > 0 ? (
                      // <Tooltip title="click to view likes" sx={{ ml: "15px" }} onClick={() => setOpen(true)}  >
                      <Typography
                        sx={{
                          fontSize: { xs: "7px", sm: "11px", md: "14px", lg: '14px' },
                          // margin: "5px",
                          height: '11px',
                          cursor: 'pointer'
                        }}
                      >
                        {item?.likeCount}
                        <span className='font-extralight text-xs'>  {item?.likeCount === 1 ? "Person Liked this Post" : "People Liked this Post"}</span>
                      </Typography>
                      // </Tooltip>
                    ) : null}

                    {item?.commentCount > 0 ? (
                      <Typography
                        sx={{
                          fontSize: { xs: "7px", sm: "11px", md: "14px", lg: '14px' }
                        }}
                      >
                        {/* {item?.commentCount} {item?.commentCount === 1 ? "person" : "people"}{" "} */}
                        {item?.commentCount}
                        <span className='text-xs font-extralight'> {item?.commentCount === 1 ? "Discussion" : "Discussions"}</span>
                      </Typography>
                    ) : null}
                  </Box>

                  <Divider />
                  <Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", p: "2px 10px", }}
                    >
                      <div className='flex items-center'>
                        {item.liked || like === true ? (
                          <IconButton aria-label="unlike" onClick={handleUnlike}>
                            <img src={LikedIcon} alt="Liked" />
                          </IconButton>
                        ) : (
                          <IconButton aria-label="like" onClick={handleAddLike}>
                            <img
                              src={likeIcon}
                              width={18}
                              height={18}
                              alt="like"
                            />
                          </IconButton>
                        )}
                        <Typography sx={{ cursor: 'pointer' }} onClick={
                          item.liked || like === true ? handleUnlike : handleAddLike
                        }> Like</Typography>
                      </div>
                      <div style={{ position: 'relative' }} >
                        <div className='flex items-center' >
                          <IconButton aria-label="share">
                            <img
                              src={ShareImg}
                              width={18}
                              height={18}
                              alt="share"
                            />
                          </IconButton>
                          <Typography sx={{ cursor: "pointer", fontSize: '14px' }}>
                            Share
                          </Typography>
                        </div>
                        {openShare ? <Box sx={{ position: 'absolute', top: '30px', right: '0px', zIndex: 1 }}>
                          <Paper sx={{ padding: '5px  10px' }}>
                            <ClickAwayListener onClickAway={handleShareClose}>
                              <MenuList
                                autoFocusItem={openShare}
                                id="composition-share"
                                aria-labelledby="composition-share"
                              >
                                <MenuItem sx={{ fontSize: '14px', padding: '3px!important' }} onClick={() => setIsShareOpen((prev) => !prev)}>Share By Url</MenuItem>
                                {isShareOpen && (
                                  <Box
                                    style={{
                                      background: '#fff',
                                      gap: '2px',
                                      position: 'absolute',
                                      right: 50,
                                      top: -30,
                                      borderRadius: '200px',
                                      display: 'flex',
                                    }}
                                  >
                                    <SocialShare
                                      url={`${process.env.REACT_APP_WEB_URL}`}
                                      size={34}
                                    />
                                  </Box>
                                )}
                                <MenuItem sx={{ fontSize: '14px', padding: '5px!important' }} disabled>Share By Content</MenuItem>

                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Box> : ''}
                      </div>


                    </Box>
                  </Box>
                </Box>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid item container spacing={2}>
                  <Grid item xs={2.5} sm={2.5} md={2} lg={1.7} xl={1.5}>
                    {item?.pageData?.profilePic?.length > 0 ||
                      item?.userData?.profilePic?.length > 0 ? (
                      <CardMedia
                        sx={{
                          width: { xs: 44, sm: 54, md: 54, lg: 54, xl: 62 },
                          height: { xs: 44, sm: 54, md: 54, lg: 54, xl: 62 },
                          // border: '3px solid #fff',
                          padding: "3px",
                          borderRadius: '12px',
                          // background: '#C6C6C6'
                        }}
                        // className="shadow-md"
                        component="img"
                        alt="doctor img"
                        image={item.pageData?.profilePic
                          ? item.pageData.profilePic
                          : item.userData?.profilePic}
                      />) : <Avatar
                      sx={{
                        borderRadius: "7px!important",
                        // border: "1px solid lightgrey",
                        width: { sm: 55, md: 65 },
                        height: { sm: 55, md: 65 },
                      }}
                    // src={medxpertsIcon}
                    />
                    }


                  </Grid>
                  <Grid item xs={8.5} sm={8.5} md={9} lg={9.2} xl={9.5}>
                    <div>
                      {item?.pageData?.fullName?.length > 0 ||
                        item?.userData?.fullName?.length > 0 ? (
                        <Typography
                          sx={{
                            fontSize: { xs: 14, sm: 14, md: '16px' },
                            fontWeight: 500,
                            cursor: "pointer",
                          }}
                        >
                          {(item?.pageData?.fullName || item?.userData?.fullName || "")
                            .charAt(0)
                            .toUpperCase() +
                            (
                              item?.pageData?.fullName ||
                              item?.userData?.fullName ||
                              ""
                            ).slice(1)}
                        </Typography>
                      ) : (
                        <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
                      )}

                      {item?.pageData?.caption?.length > 0 ||
                        item?.userData?.caption?.length > 0 ? (
                        <Typography
                          sx={{
                            fontSize: { xs: 12, sm: 12, md: 12 },
                          }}
                        >
                          {item?.pageData?.caption || item?.userData?.caption}
                        </Typography>
                      ) : null}


                      <Typography color="black">
                        <Typography
                          sx={{
                            fontSize: { xs: 12, sm: 12, md: 12 },
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            mt: '2px'
                          }}
                        >
                          <Public sx={{ fontSize: "14px", color: "#336ECC" }} />
                          {`${timeSince(moment.utc(item?.createDate).local())}`}
                        </Typography>
                        &nbsp;
                      </Typography>

                    </div>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <div className='flex justify-end'>
                      <IconButton onClick={() => navigate(-1)}>
                        <Close color='error' />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
                <Box sx={{
                  // overflowY: 'scroll',
                  overflowY: 'auto', // Ensure vertical scrolling
                  overflowX: 'hidden', // Prevent horizontal scrolling
                  maxHeight: {
                    xs: 'calc(100vh - 100px)',
                    sm: 'calc(100vh - 100px)',
                    md: 'calc(65vh - 200px)',
                    lg: 'calc(65vh - 80px)',
                    xl: 'calc(100vh - 80px)',
                  }, '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  mb: 1
                }}>

                  {/* < Typography sx={{ fontWeight: 500 }}> Description</Typography> */}
                  {item?.postType === "TEXT" ? <Divider sx={{ m: "10px 0px" }} />
                    : item?.postType === "POLL" ? <Divider sx={{ m: "10px 0px" }} />
                      : <>
                        {item.postType === "IMAGE" ?

                          <div>
                            <Typography
                              component={"p"}
                              sx={{
                                display: "-webkit-box",
                                WebkitLineClamp: expanded ? "none" : 2, // Shows 3 lines when collapsed
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: '14px',
                                lineHeight: 1.8,
                                wordBreak: 'break-word', // Prevents horizontal overflow by breaking long words
                                mt: 1
                              }}
                            >
                              {item?.description}
                              {/* ihehufeefhdhfjdssjfdssfdsjfdjsfrjirefiewidefsjssfdjsfdsfdrltreiherhewlrewhrewwlhereegjnfdsjnkfdjnfdjncvmn,vcm,cvmnvcnmvcmnfjkfsdkjfdjkvfnxn,fdm,fgj,fdgnjdfnfdfjdsjf.ksdjkshfbhfjerweoidjwischdbjcvbdfjghrejdkfcndkjxmfncnreukdjfnc sdjxmfncnkdsjxfncnneruidjkxfncmsdxjscnndkxjkhfckjdxmdfncdiukxjfnckcdsuxkjfbedsfhcjnemdsfkejd
                    ihehufeefhdhfjdssjfdssfdsjfdjsfrjirefiewidefsjssfdjsfdsfdrltreiherhewlrewhrewwlhereegjnfdsjnkfdjnfdjncvmn,vcm,cvmnvcnmvcmnfjkfsdkjfdjkvfnxn,fdm,fgj,fdgnjdfnfdfjdsjf.ksdjkshfbhfjerweoidjwischdbjcvbdfjghrejdkfcndkjxmfncnreukdjfnc sdjxmfncnkdsjxfncnneruidjkxfncmsdxjscnndkxjkhfckjdxmdfncdiukxjfnckcdsuxkjfbedsfhcjnemdsfkejd */}
                            </Typography>
                            {item?.description?.length > 80 ? (

                              <Typography
                                component={"span"}
                                onClick={() => setExpanded(!expanded)}
                                sx={{ cursor: "pointer", fontSize: 14, color: "blue" }}
                              >
                                {expanded ? "Read Less" : "Read More"}
                              </Typography>
                            ) : (
                              ""
                            )}
                            <Divider sx={{ m: "10px 0px" }} />


                          </div> : item.postType === "ARTICLE" ?

                            <FeedArticle docFeed={item} />
                            : item.postType === "EVENT" ?
                              <FeedEvent docFeed={item} /> : item.postType === "VIDEO" ?
                                <FeedVideo docFeed={item} /> : null
                        }
                      </>
                  }

                  <Box>
                    <Typography sx={{ fontWeight: 500 }}>Comments</Typography>
                    <div className='mt-2'>
                      {usertoken && ibmId ?
                        <Grid container >
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.3} xl={1}>
                            {/* <Avatar variant="rounded" /> */}
                            {
                              anonymous ?
                                <Avatar variant="rounded" />
                                : (
                                  <Avatar variant="rounded" />
                                )
                            }
                          </Grid>
                          <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.7} xl={7}>
                            <h2 className="capitalize text-ellipsis font-medium">{anonymous ? "Unknown" : patient?.name?.[0]?.text}</h2>
                          </Grid>

                          <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3}>
                            <Typography sx={{ fontSize: { md: '11px', lg: '14px' } }}>
                              Ask as Anonymous
                            </Typography>
                          </Grid>
                          <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1}>
                            <Switch
                              // size={Platform.OS === 'ios' ? 'small' : 'medium'}   
                              sx={{
                                '&.MuiSwitch-root': {
                                  top: '-9px'
                                }
                              }}
                              checked={anonymous}
                              onChange={() => {
                                setAnonymous(!anonymous);
                              }}

                            />
                          </Grid>
                        </Grid> : <Grid container spacing={2} >
                          <Grid item xs={2} sm={2} md={1.5} lg={1.3} xl={1}>
                            {/* <Avatar variant="rounded" /> */}
                            <Avatar variant="rounded" />
                          </Grid>
                          <Grid item xs={10} sm={10} md={10} lg={10.2} xl={11}>
                            <h2 className="capitalize text-ellipsis font-medium">{"Unknown"}</h2>
                          </Grid>
                        </Grid>
                      }

                      <Grid container spacing={2} alignItems={'end'}>
                        <Grid item xs={1.1} sm={1.1} md={1.1} lg={1} xl={1}>
                        </Grid>
                        <Grid item xs={8.7} sm={8.7} md={8.7} lg={9} xl={9}>
                          <div className='mt-1.5'>

                            <CommentTextField
                              multiline
                              maxRows="10"
                              className='input_width'
                              value={commentVal}
                              onChange={(e) => {
                                // if (e.target.value.length <= 255) {
                                setCommentVal(e.target.value)
                                // }
                              }}

                              placeholder="Ask your Qestion"
                            // InputProps={{
                            //   endAdornment: (
                            //     <InputAdornment position="end">

                            //       <ContainedButton text="Ask" bgcolor='#E44190' sx={{ maxHeight: '28px', borderRadius: '15px', textTransform: 'capitalize', }} disabled={!commentVal} onClick={() => addComment()} />
                            //     </InputAdornment>
                            //   ),
                            // }}
                            />
                          </div>

                        </Grid>
                        <Grid item xs={2.2} sm={2.2} md={2.2} lg={2} xl={2}>
                          <ContainedButton text="Ask" bgcolor='#E44190' sx={{ maxHeight: '28px', borderRadius: '15px', textTransform: 'capitalize', }} disabled={!commentVal} onClick={() => addComment()} />
                        </Grid>
                      </Grid>
                    </div>

                    <div>
                      {
                        ibmId &&
                        <>
                          {replyComments?.map((message, index) => {
                            return (
                              < div className='flex mt-2'>
                                <Grid container>
                                  <Grid item xs={1} sm={1} md={1.5} lg={1}>
                                    <Avatar variant='rounded' src={message?.userData?.profilePic} />
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={10.5} lg={11} sx={{ backgroundColor: '#f3f3f3', borderRadius: '10px', p: 1 }}>
                                    {/* <Typography component={'div'} sx={{ backgroundColor: '#f3f3f3', p: 1, ml: '8px!important' }}> */}
                                    {/* <div > */}
                                    <div className='flex justify-between'>
                                      <div>
                                        <div className='flex items-center gap-4'>

                                          <h2 className="capitalize text-ellipsis font-medium">{message?.userData?.fullName || "Anonymous"}</h2>

                                          {/* <Typography color="black" className="fabdetail e-align" sx={{ fontSize: '14px' }}>
                          {`${timeSince(moment.utc(item.createDate).local())}`}

                        </Typography> */}
                                        </div>
                                        <p className="italic text-ellipsis text-xs text-[#0009]">{message?.userData?.caption}</p>
                                      </div>
                                      <Typography
                                        sx={{
                                          fontSize: { xs: 12, sm: 12, md: 12 },
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "2px",
                                        }}
                                      >
                                        <Public sx={{ fontSize: "14px", color: "#336ECC" }} />
                                        {`${timeSince(moment.utc(message?.date).local())}`}
                                      </Typography>
                                      {/* <Delete sx={{ fontSize: '18px!important', cursor: 'pointer' }} onClick={() => setOpen(true)} /> */}
                                    </div>


                                    <p style={{ color: '#414141', fontSize: '15px', marginTop: '10px', wordBreak: 'break-word' }}>{message?.comment}</p>
                                    {message?.doctorData?.fullName &&
                                      <div className='mt-1 gap-2 bg-[#ffffff] p-3' style={{ borderRadius: '10px' }}>
                                        <div className='flex items-center gap-2'>

                                          {/* <FaUserDoctor style={{ width: '20px', height: '20px' }} /> */}
                                          <Avatar variant='rounded' src={message?.doctorData?.profilePic} />
                                          <div>
                                            <Typography>
                                              {message?.doctorData?.fullName}
                                            </Typography>
                                            <Typography fontSize={"12px"} >
                                              {message?.doctorData?.caption}
                                            </Typography>
                                          </div>
                                        </div>

                                        <p className='font-medium mt-1' style={{ fontSize: '15px', wordBreak: 'break-word' }}>{message?.replyBy_doctor}</p>
                                      </div>
                                    }
                                  </Grid>
                                </Grid>
                              </div>
                            )
                          })}
                        </>
                      }
                    </div>

                  </Box>

                </Box>
              </Grid>
            </Grid>
          </div>
        )
        }
        {/* <CommonDialog open={open} >
        <DialogContent>
          <Typography sx={{ fontSize: 18 }}>
            Do you want to delete comment?
          </Typography>
          <div className='flex justify-end mt-2'>
            <div className='flex justify-between gap-2'>
              <ContainedButton text="Yes" borderRadius="10px" />
              <ContainedButton text="No" borderRadius="10px" bgcolor="red" onClick={() => setOpen(false)} />
            </div>
          </div>
        </DialogContent>

      </CommonDialog> */}
        <CommonDialog open={open} >
          <DialogTitle sx={{ display: 'flex', p: '10px 30px 0px 30px', alignItems: 'center', justifyContent: 'space-between' }}>
            <div >
              Post Likes
            </div>
            <IconButton onClick={() => setOpen(false)}>
              <Close sx={{ color: 'black' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ p: '10px 30px 10px 30px' }}>
            {personLikes?.map((likes, index) => (
              <div className='flex items-center gap-4 pb-3'>
                <Avatar />
                <div className='font-medium capitalize'>
                  {likes?.userData?.fullName}</div>
              </div>
            ))

            }

          </DialogContent>

        </CommonDialog>


        <CommonDialog open={openMessage}  >
          <DialogContent sx={{ p: '10px 30px 10px 30px' }}>
            Your are unable to access the data, please log in or sign up for an account
            <Divider sx={{ mt: 1 }} />
            <div className='flex items-center justify-between p-1'>
              <Button color='error' sx={{ textTransform: 'capitalize' }} onClick={() => setOpenMessage(false)}>Cancel</Button>
              <div > <span className='text-[#1B5775] cursor-pointer hover:underline' onClick={() => navigate(
                branchName ? `/${branchName}/signup` : "/signup"
              )}>Signup </span><span>/</span> <span className='text-[#1B5775] cursor-pointer hover:underline' onClick={() => navigate(
                branchName ? `/${branchName}/login` : "/login"
              )}>Login</span></div>
            </div>
          </DialogContent>
        </CommonDialog>

      </Box >
      <ImageWithModal openImage={openImage} setOpenImage={setOpenImage} image={item.postType === "EVENT" ? item?.eventData?.eventImage : image} />
    </Box >
  )
}

export default FeedPost