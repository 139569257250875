import { Autocomplete, Box, CircularProgress, DialogActions, DialogContent, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import { CustomTextField } from '../atoms/TextField';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import CustomAutoComplete from '../atoms/CustomAutoComplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import axios from 'axios';
import URL from '../../services/api_url';
import { localstore } from '../localstore/localstore';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import { East, Verified } from '@mui/icons-material';
import SelectOptions, { MenuOptions } from '../atoms/selectoptions';
import Patientdata from '../atoms/patientdata';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormikCommoncalender from '../atoms/commoncalender';
import CommonDialog from '../atoms/commonModal';
import { jwtDecode } from 'jwt-decode';
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment';
import { debounce } from 'lodash';

const NewUserDetails = ({ IncreaseStep, }) => {
    const branchName = useSelector((state) => state.branch.branch)
    // const selectedBranchId = selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`
    const selectedBranchId = process.env.REACT_APP_PARAMITHA_ID
    const refreshToken = localstore.getRefreshToken()
    const navigate = useNavigate()
    const usertoken = localstore.getToken()
    const [profile, setProfile] = useState('')
    const [verifyMethod, setVerifyMethod] = React.useState(null);
    const [inputIntialValue, setInputIntialValue] = React.useState("");
    const [verifyOtp, setVerifyotp] = useState("");
    const [requestOpen, setRequestOpen] = useState(true)
    const [verifyOpen, setVerifyOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isloading, setIsLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [alertmsg, setAlertmsg] = useState('')
    const [alertsts, setAlertsts] = useState('')
    const [otpSubmitted, setOtpSubmitted] = useState(false); // Track OTP submission
    const [phoneNumber, setPhoneNumber] = useState("");
    const [open, setOpen] = useState(false)
    const [isVerified, setIsVerified] = useState(false);
    const [captchaToken, setCaptchToken] = useState('')
    const [phoneError, setPhoneError] = useState(null)

    const user = jwtDecode(usertoken);




    const ProfileOptions = ["My Self", "other"];
    const genderOptions = ["male", "female", "other"];


    const [startingDate, setStartingDate] = useState(null);

    const handleChangePhoneNumber = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setPhoneNumber(inputValue.slice(0, 10));
        // debouncedCheckPhone(inputValue)
        setAlertmessage('')
        setPhoneError('')
    }


    // const debouncedCheckPhone = debounce((value) => {


    // }, 1000);

    const handleChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setVerifyotp(inputValue.slice(0, 5));
        setAlertmessage('')
    }
    // console.log(captchaToken, "captchtoken")

    const refreshdata = {
        refreshToken: refreshToken,
        clientId: 'paramithaWeb'
    };

    // useEffect(() => {
    const fetchNewAccessToken = async () => {
        try {
            // Ensure refreshToken is a valid string before using it
            if (typeof refreshToken === 'string' && refreshToken.trim() !== '') {
                const response = await axios.post(
                    `${URL.paramitha}/AccessTokenByRefreshToken`,
                    refreshdata,
                    {
                        headers: {
                            realm: 'parimitha',
                        },
                    }
                );

                if (response.status === 200 || response.status === 201) {
                    const { access_token, refresh_token } = response.data;
                    const user = jwtDecode(access_token);
                    const userid = user.sub
                    const ibmid = user.ibmId
                    const roles = user.realm_access?.roles || [];
                    const hasPatientRole = roles.includes("patient");
                    localStorage.setItem("kctoken", access_token);
                    localStorage.setItem("rctoken", refresh_token);

                    if (hasPatientRole && ibmid) {
                        let username = user.preferred_username;
                        let patientName = JSON.stringify(user.name);
                        localStorage.setItem('patientName', patientName)
                        localStorage.setItem('userName', username)
                        localStorage.setItem('IbmId', ibmid)
                        localStorage.setItem('userId', userid)
                        localStorage.setItem('fixedIbmId', ibmid);
                        localStorage.setItem('pId', ibmid)

                        setAlertstatus('success');
                        setAlertmessage('Successfully Logged In!');
                        navigate('/healthportal')

                    } else if (hasPatientRole === undefined || hasPatientRole === null) {
                        alert("Does Not Exist Your Account")

                    } else {
                        navigate(branchName ? `/${branchName}/newuser` : '/newuser')
                    }
                    // Update localStorage with the new access token and refresh token

                } else {
                    console.error("Invalid refresh token:", refreshToken);
                }
            }
        } catch (error) {
            console.error("Error refreshing access token:", error);
            // Handle errors as needed, e.g., redirect to login if the refresh fails
        }
    };


    const handleSendOtp = async () => {
        // setVerifyOpen(true)
        // setRequestOpen(false)
        const mobiledata = {
            phoneNumber: phoneNumber // Format phone number for the API
        };
        const data = {
            "messagetype": "OTP",
            "apptype": "Paramitha",
            "phoneNumber": `+91${phoneNumber}`,
            "email": "",
            'realm': 'parimitha'
        }

        axios.get(`${URL.check}/check/validMobileNumber/${phoneNumber}`).then(
            res => {
                if (res.status === 200 || res.status === 201) {
                    axios.post(`${URL.paramitha}/findPatientsByPhoneNumber`, mobiledata, {
                        headers: {
                            "Authorization": `Bearer ${usertoken}`  // Include authorization token
                        }
                    })
                        .then(res => {
                            if (res.status === 200 || res.status === 201) {
                                if (captchaToken) {
                                    setLoading(true)
                                    axios.post(`${URL.sendOtp}`, data, {
                                        headers: {
                                            "Authorization": `Bearer ${captchaToken}`,
                                            "Usertoken": `${usertoken}`
                                        }
                                    }).then(res => {
                                        if (res.status === 200 || res.status === 201) {
                                            setAlertmessage(res.data.msg)
                                            setAlertstatus('success')
                                            setVerifyOpen(true)
                                            setRequestOpen(false)
                                        }


                                    }).catch((error) => {
                                        const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";

                                        setAlertmessage(errorMessage)

                                        setAlertstatus('error')
                                    }).finally(() => {
                                        setLoading(false)
                                    })

                                } else {
                                    alert('Please verify the reCAPTCHA!')
                                }
                            }
                        })
                        .catch(error => {
                            // console.log(error, "error");
                            setPhoneError(error?.response?.data)

                        })

                }
            }).catch(error => {
                if (error.response && error.response.status === 404) {
                    setPhoneError("Invalid Mobile Number")
                }
            })
    }


    const handleVerifyOtp = async () => {
        // setOtpSubmitted(true)
        const data = {
            "paramitha": "True",
            "phoneNumber": `+91${phoneNumber}`,
            "otp": verifyOtp,
            "email": "",
            'realm': 'parimitha'
        }
        setLoading(true)
        await axios.post(`${URL.verifyOtp}`, data, {
            // await axios.post(`13.233.31.166 / notify / verifyOtpparamitha`, data, {
            headers: {
                // "usertoken": `Bearer ${ usertoken } `,
                "usertoken": `${usertoken}`,
            }
        }).then(res => {
            if (res.status === 200 || res.status === 201) {
                setAlertmessage('OTP verified successfully')
                setAlertstatus('success')
                setOtpSubmitted(true)
            }

        }).catch((err) => {
            setAlertmessage(err.message)
            setAlertstatus('error')
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleRecaptchaChange = (value) => {
        setCaptchToken(value)
        setAlertmessage('')
    };
    const handleStartDate = (e) => {
        // setStartingTime(e.$d);
        setStartingDate(e.$d);
    };

    const signUpValidationSchema = yup.object().shape({
        firstName: yup.string().required('Firstname is required'),
        lastName: yup.string().required('Lastname is required'),
        selectPerson: yup.string().required("This field is required"),
        selectgender: yup.string().required("This field is required"),
        selectedDate: yup.date().required('Date of Birth is required'),
    })


    const handleSubmitData = async (items) => {
        // console.log(items, "itemssss")
        const dob = items?.selectedDate?.toISOString();
        const momentDate = moment(dob);
        const birth = momentDate.format('YYYY-MM-DD');
        const padata = items?.lastName
            ? {
                ...Patientdata?.patientData,
                gender: items?.selectgender?.toLowerCase(),
                birthDate: birth,
                resourceType: 'Person',
                name: [
                    {
                        ...Patientdata.patientData.name[0],
                        given: [items?.firstName],
                        family: items?.lastName,
                        text: items?.firstName
                    },
                ],
                telecom: [
                    {
                        ...Patientdata?.patientData?.telecom[0],
                        value: `${phoneNumber}`,
                    },
                    {
                        ...Patientdata?.patientData?.telecom[1],
                        value: `${user.email}`,
                    },
                ],
                photo: items?.image?.length > 0 ? [{ url: items?.image }] : [],
            }
            : {
                ...Patientdata?.patientData,
                gender: items?.selectgender?.toLowerCase(),
                birthDate: items?.selectedDate?.toISOString(),
                resourceType: 'Person',
                name: [
                    {
                        ...Patientdata?.patientData?.name[0],
                        given: [items?.firstName],
                        text: items?.firstName
                    },
                ],
                telecom: [
                    {
                        ...Patientdata?.patientData?.telecom[0],
                        value: `${phoneNumber}`,
                    },
                    {
                        ...Patientdata?.patientData?.telecom[1],
                        value: `${user?.email}`,
                    },
                ],
                photo: items?.image?.length > 0 ? [{ url: items?.image }] : [],
            }
        const data = {
            ...Patientdata,
            phoneNumber: `${phoneNumber}`,
            patientData: padata,
        }
        setIsLoading(true)
        try {
            const res = await axios.post(`${URL.paramitha}/keycloak/Ibm/addPatient?orgid=${selectedBranchId}`, data, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'Authorization': `Bearer ${usertoken}`,
                    'realm': 'parimitha'
                }
            })
            if (res.status === 200 || res.status === 201) {
                setAlertmsg("data resistered successfully")
                setAlertsts('success')
                fetchNewAccessToken()
                // setOpen(true)

                // navigate('/login')
                // localStorage.removeItem('kctoken')
            }
        } catch (error) {
            // setAlertmsg(error.message)
            // setAlertsts('error')
            alert(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box className="px-3 md:px-10 md:py-2" sx={{ p: { xs: '0px 0px', sm: '0px 0px', md: '0px 80px', lg: '0px 100px' } }}>
            <Grid container spacing={5} >
                <Grid item xs={12} sm={12} md={4} >
                    <Box  >
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, fontWeight: 600, color: "#1B5775" }}>
                                Step 2</Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, fontWeight: 600, color: "#000000" }}>
                                Verify your Contact details</Typography>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 400, color: "#000000" }}>
                                Verify your Profile with Phone number or Email ID provided During your registration</Typography>
                        </Box>
                        {!otpSubmitted ?
                            <>
                                <Box sx={{ mt: 1 }}>
                                    <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 500 }}>
                                        Phone Number</Typography>
                                </Box>
                                <Box sx={{ mt: 1 }}>

                                    {/* <CustomTextField
                                        // label="Display name"
                                        placeholder="Enter your Phone Number"
                                        type={'text'}
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        // name="fullName" 
                                        value={phoneNumber}
                                        onChange={handleChangePhoneNumber}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSendOtp()
                                            }
                                        }}
                                    /> */}
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type={'text'}
                                        value={phoneNumber}
                                        onChange={handleChangePhoneNumber}
                                        // onBlur={handleBlur('phone')}

                                        placeholder="Enter Phone Number"
                                        sx={{
                                            width: '100%',
                                            ".MuiInputBase-root": {
                                                borderRadius: '7px',
                                                // backgroundColor: "rgb(243, 244, 246)"
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#59D8FA',
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +91
                                                </InputAdornment>
                                            )
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSendOtp()
                                            }
                                        }}
                                    // error={touched.phone && Boolean(errors.phone)}

                                    />
                                    {phoneError && <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                        {phoneError}</Typography>}
                                </Box>
                                {/* <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /> */}
                                {
                                    !verifyOpen && <Box sx={{ mt: 4, transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            onChange={handleRecaptchaChange}
                                            size="normal"
                                        // style={{ transform: 'scale(0.9)' }}
                                        />
                                    </Box>
                                }
                                {
                                    requestOpen &&
                                    <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                                        <div></div>
                                        <div>
                                            <OutlinedButton
                                                width="160px"
                                                height="32px"
                                                fontSize="14px"
                                                borderColor="#1B5775"
                                                text="Request OTP"
                                                color="#1B5775"
                                                borderRadius="7px"
                                                fontWeight={600}
                                                onClick={handleSendOtp} // onClick event handler
                                                disabled={phoneNumber.length < 10}
                                                startIcon={null}
                                                endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} />
                                        </div>
                                    </Box>
                                }
                                {verifyOpen &&
                                    <>
                                        <Box sx={{ mt: 1 }}>
                                            <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 500 }}>
                                                Verify OTP</Typography>
                                        </Box>
                                        <Box sx={{ mt: 1 }}>
                                            <CustomTextField
                                                // label="Display name"
                                                placeholder="Enter OTP Here"
                                                type={'text'}
                                                borderColor="#59D8FA"
                                                borderRadius="7px"
                                                // name="fullName" 
                                                value={verifyOtp}
                                                onChange={handleChangeOtp}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleVerifyOtp()
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                                            <div></div>
                                            <div>
                                                <OutlinedButton
                                                    width="140px"
                                                    height="32px"
                                                    fontSize="14px"
                                                    borderColor="#1B5775"
                                                    text="Verify OTP"
                                                    color="#1B5775"
                                                    borderRadius="7px"
                                                    fontWeight={600}
                                                    onClick={handleVerifyOtp} // onClick event handler
                                                    disabled={!verifyOtp}
                                                    startIcon={null}
                                                    endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} />
                                            </div>
                                        </Box>
                                    </>
                                }
                                <div>
                                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                </div>


                            </> :
                            <Box sx={{ mt: 1 }}>
                                <Typography sx={{ fontSize: { xs: 18, sm: 18, md: 18, lg: 20 }, fontWeight: 600, display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Verified />
                                    Mobile Number Verified
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8}
                    style={{ pointerEvents: !otpSubmitted ? 'none' : 'auto', opacity: !otpSubmitted ? 0.4 : 1 }}
                > {/* Disable second grid if OTP not submitted */}

                    <Box className='px-3'
                    >
                        <Box  >
                            <Box sx={{ mt: 1 }}>
                                <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, fontWeight: 600, color: "#1B5775" }}>
                                    Step 3</Typography>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, fontWeight: 600, color: "#000000" }}>
                                    Complete Patient Profile</Typography>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Typography sx={{ fontSize: { xs: 14, sm: 14, md: 14 }, fontWeight: 400, color: "#000000" }}>
                                    You can add multiple Profile, create a profile for each person in your family. Add primary profile Here, your can add other profile later
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 2 }}>
                                <Formik
                                    validationSchema={signUpValidationSchema}
                                    initialValues={{
                                        firstName: "", lastName: "", selectPerson: "My Self", selectgender: "", selectedDate: '',
                                    }}
                                    onSubmit={(values) => {
                                        handleSubmitData(values)
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        isValid,
                                        values,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        touched,
                                        isSubmitting,
                                        setFieldValue,
                                        setFieldTouched,

                                    }) => (
                                        <Box>
                                            <Box>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Box sx={{ mb: 1 }}>
                                                            <label>Profile Created for</label>
                                                        </Box>


                                                        <MenuOptions borderRadius="7px" value={values.selectPerson} onChange={(event) => setFieldValue('selectPerson', event.target.value)}
                                                            error={touched.selectPerson && Boolean(errors.selectPerson)}>

                                                            {ProfileOptions.map(option => (
                                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                                            ))}
                                                        </MenuOptions>
                                                        {errors.selectPerson && touched.selectPerson && (
                                                            <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                                {touched.selectPerson && errors.selectPerson}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6}>


                                                    </Grid>

                                                </Grid>
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Box sx={{ mb: 1 }}>
                                                            <label>First Name</label>
                                                        </Box>
                                                        <CustomTextField
                                                            placeholder="Enter Your Full Name here"
                                                            type={'text'}
                                                            borderColor="#59D8FA"
                                                            value={values.firstName}
                                                            borderRadius="7px"
                                                            // onChange={(event) => { handleChangeReg(event.target.name, event.target.value) }} // Corrected: passing event object
                                                            onChange={handleChange('firstName')}
                                                            handleBlur={handleBlur('firstName')}
                                                            error={touched.firstName && Boolean(errors.firstName)}
                                                        />
                                                        {errors.firstName && touched.firstName && (
                                                            <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                                {touched.firstName && errors.firstName}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Box sx={{ mb: 1 }}>
                                                            <label>Last Name</label>
                                                        </Box>
                                                        <CustomTextField
                                                            placeholder="Enter Your Full Name here"
                                                            type={'text'}
                                                            borderColor="#59D8FA"
                                                            value={values.lastName}
                                                            // onChange={(event) => { handleChangeReg(event.target.name, event.target.value) }} // Corrected: passing event object
                                                            onChange={handleChange('lastName')}
                                                            handleBlur={handleBlur('lastName')}
                                                            borderRadius="7px"
                                                            error={touched.lastName && Boolean(errors.lastName)}
                                                        />
                                                        {errors.lastName && touched.lastName && (
                                                            <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                                {touched.lastName && errors.lastName}
                                                            </Typography>
                                                        )}

                                                    </Grid>
                                                </Grid>

                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Box sx={{ mb: "4px" }}>
                                                            <label>Gender</label>
                                                        </Box>

                                                        <SelectOptions borderRadius="7px" value={values.selectgender} defaultName="Select your Gender" onChange={(event) => setFieldValue('selectgender', event.target.value)} error={touched.selectgender && Boolean(errors.selectgender)}>
                                                            {genderOptions.map(option => (
                                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                                            ))}
                                                        </SelectOptions>
                                                        {errors.selectgender && touched.selectgender && (
                                                            <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                                                                {touched.selectgender && errors.selectgender}
                                                            </Typography>
                                                        )}


                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} sx={{ '.MuiStack-root': { paddingTop: '0px' } }}>
                                                        <Box sx={{ mb: "4px" }}>
                                                            <label>Date of Birth</label>
                                                        </Box>

                                                        <Field
                                                            name="selectedDate"
                                                            component={FormikCommoncalender}
                                                            maxDate={dayjs()}
                                                            width="100%"

                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Box>
                                            <ErrorStatusMsg alertmessage={alertmsg} alertstatus={alertsts} />
                                            <Box>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                                    <div></div>
                                                    <div>
                                                        <ContainedButton
                                                            width="150px"
                                                            height="33px"
                                                            bgcolor="#1B5775"
                                                            borderRadius="7px"
                                                            text="Submit"
                                                            onClick={handleSubmit}

                                                            // onClick event handler
                                                            // disabled={!isValid}
                                                            // disabled={!values.firstName || !values.Selectgender || !values.LastName || !values.selectPerson || !values.startTime}
                                                            // disabled={!values.username || !values.createpassword}
                                                            startIcon={null}
                                                            endIcon={isloading ? <CircularProgress size={20} color='inherit' /> : null}
                                                        />
                                                    </div>
                                                </Box>
                                            </Box>

                                        </Box>
                                    )}

                                </Formik>


                            </Box>


                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <CommonDialog open={open} sx={{ '.MuiDialog-paper': { bottom: { md: "0px" }, top: { xs: "0px", sm: "0px", md: '-100px' } } }}>
                <DialogContent>
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                        Your details are successfully registered please login with  below username or email
                    </Typography>
                    <Typography >
                        User Name: <span className='font-medium mt-1'>{user.preferred_username}</span>
                    </Typography>
                    <Typography >
                        Email Address:<span className='font-medium mt-1'>{user.email} </span>
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ m: 0, justifyContent: 'end' }}>
                    <OutlinedButton text="Login" height="30px" borderColor="#1B5775" color="#1B5775" width="130px" endIcon={<East />} onClick={() => {
                        navigate(branchName ? `/${branchName}/login` : '/login')
                        localStorage.removeItem('kctoken')
                    }} />
                </DialogActions >
            </CommonDialog >
        </Box >
    )
}

export default NewUserDetails